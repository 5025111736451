import { Box, Paper } from '@mui/material'
import React from 'react'
import './Help.css'
import { Link } from 'react-router-dom';


function Help() {
  return (
    <div className="help">
        <h3>Help center</h3>
        <Box className="help__box__container">
            <div className="help__box__left">
                <h4>Welcome to the Help Center</h4>
                <ul>
                    <li><Link to="/">Item 1</Link></li>
                    <li><Link to="/">Item 2</Link></li>
                    <li><Link to="/">Item 3</Link></li>
                    <li><Link to="/">Item 4</Link></li>
                    <li><Link to="/">Item 5</Link></li>
                </ul>
            </div>
            <Paper className='help__box__right' elevation={3}>
                <h4>Click the links below</h4>
                <ul>
                    <li><Link to="/">Item 1</Link></li>
                    <li><Link to="/">Item 2</Link></li>
                    <li><Link to="/">Item 3</Link></li>
                    <li><Link to="/">Item 4</Link></li>
                    <li><Link to="/">Item 5</Link></li>
                </ul>

            </Paper>
        
        </Box>
    </div>
  )
}

export default Help