import React, { useEffect, useState } from 'react'
import './Accounts.css'
import './Datagrid.css'
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Button, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import RefreshIcon  from '@mui/icons-material/Refresh';
import axios from 'axios';

import moment from 'moment';
import ActionButtons from './ActionButtons';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


function Accounts() {
  const navigate = useNavigate();
  const [rowId, setRowId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    { field: 'id', headerName: 'ID', type: 'number', width: 60, cellClassName: "dataGrid__cell__center" },
    {
      field: 'name',
      headerName: 'Name',       
      cellClassName: "dataGrid__cell",
      flex: 0.3,
      minWidth: 120
    },
    {
      field: 'email',
      headerName: 'Email',     
      cellClassName: "dataGrid__cell",
      flex: 0.3,
      minWidth: 120,
      renderCell: params=> <Link href={`mailto:${params.row.email}`}>{params.row.email}</Link>
    },
    {
      field: 'username',
      headerName: 'Username',   
      cellClassName: "dataGrid__cell",
      flex: 0.3,
      minWidth: 120
    },
    {
      field: 'group',
      headerName: 'Group',   
      cellClassName: "dataGrid__cell",
      flex: 0.2,
      minWidth: 120
    },
    {
      field: 'last_login',
      headerName: 'Last Login',
      width: 200,
      renderCell: params => (params.row.last_login) ? moment(params.row.last_login).format('MMM Do YYYY h:mm:ss a') : 'Never'
    },
    {
      field: 'actions',
      headerName: 'Actions',   
      cellClassName: "dataGrid__cell",
      width: 100,
      type: 'actions',
      renderCell: params => <ActionButtons { ...{params, handleClickDelete, handleClickOpen}}/>
        
      
    }
  ];
  

  const {token, apiURL} = useAuth();
  const getAccounts = async () => {
    setIsLoading(true);
    const payload = {
      
    }
  
    axios.get(`${apiURL}/api/accounts`, payload)
      .then(response => {                
        setAccounts(response.data.data);        
        setIsLoading(false);
      })
  }
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    getAccounts();

  }, [rowId])

  const [open, setOpen] = React.useState(false);
  const [targetId, setTargetId] = useState(null);

  const handleClickDelete = (id) => {
    setOpen(true);
    setTargetId(id);
  };

  const handleClickOpen = (id) => {
      navigate(`/accounts/${id}`);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    if(!targetId) return;
    axios.delete(`${apiURL}/api/accounts/${targetId}`)
      .then(response => {                
        setAccounts(accounts.filter(item => { return(item.id != targetId) }))
        setOpen(false);
        setTargetId(null);
      })
  };

  const addNew = () => {
    navigate("/accounts/new")
  }
  const refetch = () => {
    getAccounts();
  }

  return (
    <>
    <div className="content-heading">
      <div>Accounts
        <small>Manage users and permissions</small>
      </div>
      <div className="ml-auto">
        <button className='btn btn-sm btn-success' onClick={addNew}>Add</button> <button className='btn btn-sm btn-primary' onClick={refetch}><RefreshIcon /> Refresh</button>
      </div>
    </div>
    <div className="row">
    
    <Box  className="accounts__box" sx={{ height: 720, width: '100%' }}>
          <DataGrid
            rows={accounts}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[50]}
            rowHeight={30}
           
            components={{ Toolbar: GridToolbar }}
            
            /*checkboxSelection*/
            /*disableSelectionOnClick*/
            /*experimentalFeatures={{ newEditingApi: true }}*/
            
          />
        </Box>
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm deleting account"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleDelete} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {isLoading ? (<div className='loading'><span>Loading...</span></div>) : <></>}
    </div>
    </>
    
  )
}

export default Accounts