import { Link } from 'react-router-dom'
import React from 'react'
import './Footer.css'

function Footer() {
  return (
    <footer className="footer-container"><span>Ver 2.0 © 2023 - Virtuous Ventures</span></footer>
  )
}

export default Footer