import React from 'react'
import './Projects.css'
import './Datagrid.css'
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

const columns = [
  { field: 'id', headerName: 'ID', type: 'number', width: 60, cellClassName: "dataGrid__cell__center" },
  {
    field: 'name',
    headerName: 'Project',       
    cellClassName: "dataGrid__cell",
    flex: 0.33,
    minWidth: 120
  },
  {
    field: 'client',
    headerName: 'Client',     
    cellClassName: "dataGrid__cell",
    flex: 0.33,
    minWidth: 120
  },{
    field: 'progress',
    headerName: 'Progress',     
    cellClassName: "dataGrid__cell",
    flex: 0.33,
    minWidth: 120
  },
  {
    field: 'actions',
    headerName: 'Actions',   
    cellClassName: "dataGrid__cell",
    width: 100
  }
];

const rows = [
  { id: 1, name: 'Virtue Demo', client: 'Demo Client', progress: 16.5},
  { id: 2, name: 'Virtue Demo', client: 'A new Client', progress: 28.44},
];

function Projects() {
  const navigate = useNavigate();

  const openProject = (e) => {
    
  if (!e.id) return;
    navigate(`/projects/${e.id}`)
  }
  return (
    <div className='projects'>
        <div className="projects__header">
          <h3>Projects</h3>
          <button className="btn btn-primary btn-sm">Add</button>
        </div>

        <div  className="projects__box" sx={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[50]}
            rowHeight={30}
            onRowDoubleClick={openProject}            
          />
        </div>
    </div>
  )
}

export default Projects