import React, { useContext } from 'react'
import { FormContext } from '../FormContext'

function Select({field_id, field_label, field_name, field_value, field_options}) {
    const { handleInputChange } = useContext(FormContext);
  return (
    <div className="form-group ">
        <label htmlFor={field_id}>{field_label}</label>
        <select 
            value={field_value} 
            className="form-select custom-select" 
            name={field_name} 
            id={field_id}
            onChange={event => handleInputChange(field_name, event)}
        >
            <option value="">Select...</option>
            {field_options.length && field_options.map((option, i) => <option value={option.option_value}>{option.option_label}</option>)}
        </select>
    </div>
  )
}

export default Select