import React, { useContext } from 'react'

function File({fieldChanged, field}) {
    
  return (
    <div className="form-group ">
        <label htmlFor={field.field_name}>{field.label}</label>
        <div className="custom-file">
            <input onChange={fieldChanged} type="file" id={field.field_name} name={field.field_name} className="custom-file-input"/>
            <label class="custom-file-label" for="exampleCustomFileBrowser">Choose file</label>
        </div>
    </div>
  )
}

export default File