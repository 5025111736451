import React, { useState, useEffect } from 'react'
import FieldGroup from './FieldGroup';
import TextInput from './TextInput';
import File from './File';
import Combo from './Combo';
import MultiColumn from './MultiColumn';

const fieldMeetsCondition = (values) => (field) => {
    if(field.conditional && field.conditional.field) {
        const segments = field.conditional.field.split("_");
        const fieldId = segments[segments.length - 1];
        return values[fieldId] === field.conditional.value;
    }
    return true;
}

const Form = ({ formData, setFormValues }) => {

    const [pageData, setPageData] = useState(formData);

    const [values, setValues] = useState({});

    useEffect(() => {
        const upcomingPageData = formData;
        setPageData(upcomingPageData);

        setValues((currentValues) => {
            const newValues = upcomingPageData.reduce((obj, field) => {
                if(field.component === "field_group" || field.component === "multi_column" || field.component === "three_column") {
                    for (const subField of field.fields) {
                        obj[subField.field_name] = "";
                        if (subField.component ==="file")  
                            //console.log(subField)
                            obj[subField.field_name] = "";
                    }
                } else if (field.component ==="file") { 
                    obj[field.field_name] = "";
                    
                } else {
                    obj[field.field_name] = "";
                }

                return obj;
            }, {});
            
            return Object.assign({}, newValues, currentValues);
        });
    }, [formData]);

    const fieldChanged = (fieldId, value) => {

        setValues((currentValues) => {
            console.log(typeof fieldId)
            if(typeof fieldId !== 'string'){
                let files = fieldId.target;
                if(!files.hasAttribute('multiple') && files.files.length) 
                    currentValues[files.name] = files.files[0];
                else if(files.hasAttribute('multiple') && files.files.length)
                    currentValues[files.name] = files.files;
            }else{
                currentValues[fieldId] = value;
            }
            setFormValues(currentValues);
            return currentValues;
            
        });
        
        setPageData((pageData) => {
            //console.log(pageData)
            return pageData;
        });

    }

    const fileHandler = (e) => {
        console.log(e.target.files[0])
    }

    const onSubmit = (e) => {
        e.preventDefault();
        
    }

    const getValues = () => {
        return values;
    }

  return (
    <form onSubmit={onSubmit}>
        {pageData &&
        pageData
            .filter(fieldMeetsCondition(values))
            .map((field) => {
                switch (field.component) {
                    case "field_group":
                        return (<FieldGroup 
                            key={field.field_name} 
                            field={field}
                            fieldChanged={fieldChanged}
                            values={values}/>
                                )
                    case "multi_column":
                        return (<MultiColumn 
                            key={field.field_name} 
                            field={field}
                            columns={field.columns}
                            fieldChanged={fieldChanged}
                            values={values}/>
                            )
                    case "options":
                        return (<div 
                            key={field.field_name} 
                            field={field}
                            fieldChanged={fieldChanged}
                            values={values[field.field_name]}>
                                Options
                            </div>)
                    case "select":
                        return (<Combo 
                            key={field.field_name} 
                            field={field}
                            fieldChanged={fieldChanged}
                            values={values[field.field_name]}/>
                             )
                    case "textbox":
                        return (<div>
                                <p>{field.content}</p>
                            </div>
                        )
                    case "file":
                        return (<File
                                    key={field.field_name}
                                    field={field}
                                    fieldChanged={fileHandler}
                            />
                        )
                    default:
                        return (
                            <TextInput
                                key={field.field_name}
                                field_id={field.field_name}
                                field_label={field.label}    
                                field_name={field.field_name}
                                field_type={field.type}
                                rows={field?.rows}
                                fieldChanged={fieldChanged}
                                value={values[field.field_name]}
                            />)
                }

            })}
        
    </form>
  )
}

export default Form