import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { Card, Col, Form, Row, Table } from 'react-bootstrap'
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';

function Contract() {
    const qp = useParams();
    const {token, apiURL} = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const [contract, setContract] = useState({
        id: null,
        start_date: null,
        end_date: null,
        actual_start_date: null,
        actual_end_date: null,
        files: []
    });
    const handleInputChange = (e) => {
        const field = e.target.name;
        const value = e.target.value;

        setContract({...contract, [field]: value});
    }
    const getContract = async (id, contractor) => {
        if(id == 'new') return;
        setIsLoading(true);
        const payload = {
        
        }
    
        axios.get(`${apiURL}/api/contractors/${contractor}/contract/${id}`, payload)
        .then(response => {                
            setIsLoading(false);
            setContract(response.data.data);
        })
    }

    const handleDiscard  = () => {
        navigate(`/contractors/${qp.id}`)
    }

    useEffect(() => {
        getContract(qp.contract, qp.id);
        
    }, [qp.id]);

  return (
    <>
    <div className="content-heading">
      <div>Contract
      </div>
      <div className="ml-auto">
        <button className='btn btn-sm btn-success' onClick={()=>{}}>Save</button> <button className='btn btn-sm btn-secondary' onClick={()=>{handleDiscard()}}>Return</button>
      </div>
    </div>
    <Card>
        <Card.Header>Files</Card.Header>
        <Card.Body>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="start_date">
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control type="text" value={contract.start_date} name="start_date" onChange={handleInputChange} placeholder="Start Date" />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="end_date">
                        <Form.Label>End Date</Form.Label>
                        <Form.Control type="text" value={contract.end_date} name="end_date" onChange={handleInputChange} placeholder="End Date" />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="actual_start_date">
                        <Form.Label>Actual Start</Form.Label>
                        <Form.Control type="text" value={contract.actual_start_date} name="actual_start_date" onChange={handleInputChange} placeholder="Actual Start" />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="actual_end_date">
                        <Form.Label>Actual End</Form.Label>
                        <Form.Control type="text" value={contract.actual_end_date} name="actual_end_date" onChange={handleInputChange} placeholder="Actual End" />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Form.Group className="mb-3 d-none" controlId="actual_end_date">
                    <Form.Label>Status</Form.Label>
                    <Form.Select aria-label="Default select example">
                        <option>-</option>
                        <option value="started">Started</option>
                        <option value="finishd">Two</option>
                        <option value="3">Three</option>
                    </Form.Select>
                </Form.Group>
            </Row>
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>File</th>
                        <th>Type</th>
                        <th>Size</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>File.zip</td>
                        <td>Compressed File</td>
                        <td>512Kb</td>
                        <td>-</td>
                    </tr>
                </tbody>
            </Table>
        </Card.Body>
    </Card>
    </>
  )
}

export default Contract