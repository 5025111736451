import React from 'react'
import { Delete, Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useAuth } from './AuthProvider';


function ActionButtons({params, handleClickOpen, handleClickDelete}) {

const handleOpen = () => {
  handleClickOpen(params.id);
}

const handleDelete = () => {
  handleClickDelete(params.id);
}

const navigate = useNavigate();

  return (
    <>
    <IconButton onClick={handleOpen} size="small" aria-label='Edit'>
        <Edit/>
    </IconButton>
    <IconButton onClick={handleDelete} size="small" aria-label='Delete'>
        <Delete/>
    </IconButton>
    </>
  )
}

export default ActionButtons