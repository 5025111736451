import React, { useContext } from 'react'
import { FormContext } from '../FormContext'

function CustomQA({field_id, field_label, field_name}) {
    const { handleInputChange } = useContext(FormContext);
  return (
    <>
        <div class="col-md-12 pt-2 pt-2 text-sm">{field_label}</div>
        <div class="col-md-6  pt-2 pb-2">
            <div class="row text-sm">
                <div class="col-sm-1 col-2"><input type="checkbox" name={`chk_${field_name}_yes`}/> Yes</div>
                <div class="col-sm-1 col-2"><input type="checkbox" name={`chk_${field_name}_na`}/> N/A</div>
                <div class="col-sm-4 col-8"><input type="text" name={`${field_name}_initial`} class="form-control form-control-sm" placeholder="Initials"/></div>
                <div class="col-sm-6"><input type="text" name={`${field_name}_comment`} class="form-control form-control-sm" placeholder="Deficiencies"/></div>
            </div>
        </div>
        <hr />
    </>
    
  )
}

export default CustomQA