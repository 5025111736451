import React from 'react'
import './MenuItem.css'
import {useNavigate} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function MenuItem({ icon, route, title }) {
  const isActive = false;
    const navigate = useNavigate();

    const navigateTo = (route) => {
    if( route ) {
      navigate(route)
      //window.location = route;
    }
  }
  return (
    <>
          <li className={isActive ? 'active' : ''} onClick={(e) => {e.preventDefault(); navigateTo(route)}}>
            <a title={title} href={route}>
              
              <em className="icon-grid"><FontAwesomeIcon icon={icon} /></em>
              <span>{title}</span>
            </a>
          </li>   

              {/*<div className="float-right badge badge-success">30</div>*/}
              {/*(Icon) ? <Icon className="menuItem__icon" /> : ''}{/*<em className="icon-grid"></em>*/}
            
    </>
  )
}

export default MenuItem