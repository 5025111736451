import React, { useEffect, useState } from 'react'
import './AccountDetail.css'
import axios from 'axios';
import  AuthProvider , { useAuth } from './AuthProvider'
import Input from './form/Input';
import Select from './form/Select'
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Box, FormControl, InputLabel, MenuItem, OutlinedInput, TextField } from '@mui/material';
import { FormContext } from './FormContext';


function AccountDetail() {
    const navigate = useNavigate();
    const { apiURL, getDataProvider} = useAuth();

    const [isLoading, setIsLoading] = useState(false);
    
    const [account, setAccount] = useState({
        id: null,
        name: '',
        email: '',
        username: '',
        group: '',
        password: '',
        passwordConfirm: ''
    });

  const handleInputChange = ((name, e) => {
    let field = name;
    let value = e.target.value
    setAccount({...account, [field] : value})
  })
  
  const qp = useParams();

    const getAccount = async () => {
        if(!qp.id) return;
        setIsLoading(true);

        axios.get(`${apiURL}/api/accounts/${qp.id}`)
        .then(response => {          
            setAccount(response.data.data[0]); 
            setIsLoading(false);
        })
    }

    const handleDiscard = (e) => {
        navigate(`/accounts`)
    }

    const handleSave = async (e) => {
      e.preventDefault();
      if(!account.name || !account.email || !account.username || !account.group ) return;

      setIsLoading(true);

      if(account.id){
        axios.put(`${apiURL}/api/accounts/${account.id}`, account)
        .then(response => {
          console.log(response);
          handleDiscard(null);
        })
      }else{
        axios.post(`${apiURL}/api/accounts`, account)
        .then(response => {
          console.log(response);
          handleDiscard(null);
          
        })
      }
      setIsLoading(false);
    }
    const handleDelete = (e) => {

    }
  useEffect(() => {
    getAccount();
    
  }, [])
  return (
    <>
    <FormContext.Provider value={{handleInputChange}}>
    <div className="content-heading">
      <div>Account
        
      </div>
      <div className="ml-auto">
          <button className="btn btn-sm btn-success" onClick={handleSave}>Save</button> <button className="btn btn-sm btn-secondary" onClick={handleDiscard}>Discard</button> <button className="btn btn-sm btn-warning" onClick={handleDelete}>Delete</button>
        </div>
    </div>
    
    <form className="row"
      noValidate
      autoComplete="off" 
      onSubmit={handleSave}>
        <div className="accountDetail__formFields row">
          <div className="col-sm-6 col-lg-4">
            <Input field_name="name" field_id="name" field_label="Name" field_value={account?.name} />
          </div>
          <div className="col-sm-6 col-lg-4">
            <Input field_name="username" field_id="username" field_label="Username" field_value={account?.username} />
          </div>
          <div className="col-sm-6 col-lg-4">
            <Input field_name="password" field_id="password" field_label="Password" field_value={account?.password} field_type="password"/>
          </div>
          <div className="col-sm-6 col-lg-4">
            <Input field_name="passwordConfirm" field_id="passwordConfirm" field_label="Password Confirmation" field_value={account?.passwordConfirm} field_type="password"/>
          </div>
          <div className="col-sm-6 col-lg-4">
            <Select field_name="group" field_id="group" field_label="Group" field_options={getDataProvider('groups').map(item => ({option_value: item.id, option_label: item.name}))} />
          </div>
        
        </div>
            
        
      </form>
      
      {isLoading ? (<div className='loading'><span>Loading...</span></div>) : <></>}
    
    </FormContext.Provider>  
    </>
    
  )
}

export default AccountDetail