import AppWrapper from "./components/AppWrapper";
import { BrowserRouter as Router } from 'react-router-dom'
import AuthProvider from "./components/AuthProvider";

import './App.css';

import './styles/app.scss'

function App() {
  
  
   return (
    <Router>
      <AuthProvider>
        <AppWrapper />
      </AuthProvider>
    </Router>
  );
}

export default App;
