import React from 'react'
import './JournalLog.css'

function JournalLog() {
  return (
    <div className='journalLog'>
        <h3>Journal Log</h3>
    </div>
  )
}

export default JournalLog