
export default function MaoDeObraInput({onChange, name, contratados,label, id, data, disabled = false}) {
    return (<div className="row form-group mb-2">
        <label className="col-xl-6 col-form-label">{label}</label>
        <div className="col-xl-3">
            {contratados}
        </div>
        <div className="col-xl-3">
            <input type="text" id={id} name={name} onChange={onChange} value={data ? data : ''} disabled={disabled} className="form-control" />
        </div>
    </div>)
}