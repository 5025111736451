import React from 'react'
import './LiveSupport.css'

function LiveSupport() {
  return (
    <div className="liveSupport">
        <h3>
            LiveSupport
        </h3>
    </div>
  )
}

export default LiveSupport