import React, { useEffect, useState } from 'react'
import './Login.css'
import { useAuth, CheckToken } from './AuthProvider'
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Login() {
  const { onLogin, token } = useAuth();
  const {formData, setFormData} = useState({
    email: '',
    password: ''
  });
  const authToken = localStorage.getItem('temp_token');
  
  useEffect(() => {
      if(authToken) {
        console.log('Check if token is valid');
        //CheckToken(authToken)
      }

  }, [])


  const handleLoginInputs = (e) => {
    //console.log(e)
  }

    
    return (
    <div className='login'>
        <div className='login__rightPanel'>
          {/* Virtue Logo */}
          <img src="./logo.png" alt=""/>
          <span className="login__subLogo">ENTER YOUR CREDENTIALS</span>
          <form onSubmit={onLogin} className="w-100">
          
	
            <input type="hidden" name="ref" value=""/>
            <div className="form-group">
              <div className="input-group with-focus">
                <input className="form-control border-right-0" id="exampleInputEmail1"  type="username" name="email" placeholder="E-mail or Username" autoComplete="off" required="" onChange={handleLoginInputs} value={formData?.email}/>
                <div className="input-group-append">
                  <span className="input-group-text text-muted  border-left-0">
                  
                  <FontAwesomeIcon icon={faEnvelope}/>
                  </span>
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="input-group with-focus">
                <input className="form-control border-right-0" id="exampleInputPassword1" name="password" type="password" placeholder="Senha" onChange={handleLoginInputs} value={formData?.password} required=""/>
                <div className="input-group-append">
                  <span className="input-group-text text-muted  border-left-0">
                  <FontAwesomeIcon icon={faLock}/>
                  </span>
                </div>
              </div>
            </div>
            <div className="clearfix">
              <div className="checkbox c-checkbox float-left mt-0">
                <label>
                <input type="checkbox" value="on" name="remember"/>
                <span className="fa fa-check"></span> Keep signed-in?</label>
              </div>
              <div className="float-right d-none">
                <a className="text-muted" id="forgotPasswordLink" href="#">Forgot your password?</a>
              </div>
            </div>
            <input type="hidden" name="token" value="8753d46f6561c284d2fcd9a4765932fe"/>
            <button className="btn btn-block btn-warning mt-3" type="submit">Sign in</button>

          </form>
          

        </div>
    </div>
  )
}

export default Login