import React from 'react'
import './Documents.css'

function Documents() {
  return (
    <div className='documents'>
        <h3>Documents</h3>
    </div>
  )
}

export default Documents