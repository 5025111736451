import React, { useEffect, useState } from 'react'
import './Dwr.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { Refresh } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons'
import Calendar from './Calendar';

function Dwr() {
  const {token, apiURL, hasPermission} = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [dwrs, setDwrs] = useState({
    itemsToApprove: [],
    myItems: [],
    finishedItems: []

  });
  const statuses = {
    "-1": "-",
    "0": "Rejected",
    "1": "Approved"
  }
  const getDwrs = async (mode) => {
      //modes: ongoing, tome, approved
    setIsLoading(true);
    switch(mode) {
      case 'tome' :
        axios.get(`${apiURL}/api/dwr`)
        .then(response => {
          console.log(response.data.data)
          setDwrs(response.data.data);
          setIsLoading(false);
        })
        break;
      default:
        break;
      
    }
  }
  
  const seeDwr = (id) => {
    navigate(`/dwr/${id}`);
  }
  const newDwr = (e) => {
    navigate(`/dwr/new`);
  }

  useEffect(() => {
    getDwrs('tome');
  }, [])

  const refetch = () => {
    getDwrs('tome');
  }
  const measurement = () => {
    navigate(`/measurement`);
  }
  return (
    <>
    <div className="content-heading">
      <div>Cost Chronicles
      </div>
       <div className="ml-auto">
       {hasPermission('dwr', 'insert') && <button className='btn btn-sm btn-success' onClick={newDwr}>Add new</button> }<button className='btn btn-sm btn-secondary' onClick={refetch}><Refresh/></button>  {hasPermission('dwr', 'measurement') && <button className='btn btn-sm btn-secondary' onClick={measurement}>Measurements</button>}
      </div>
    </div>
    <Calendar data={dwrs} />
    
    {isLoading ? (<div className='loading'><span>Loading...</span></div>) : <></>}
    </>
  )
}

export default Dwr