import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { FormContext } from './FormContext';
import  AuthProvider , { useAuth } from './AuthProvider'

function DwrForm() {
    const navigate = useNavigate();
    const { apiURL, getDataProvider} = useAuth();

    const [isLoading, setIsLoading] = useState(false);
    const today = new Date();
    
    const [dwr, setDwr] = useState({
        contractor_id: 0,
        contract: '',
        discipline_id: 0,
        area_id: '',
        datetime: [today.getFullYear(), (today.getMonth()+1 < 10) ? "0" + (today.getMonth()+1) : today.getMonth()+1, (today.getDate() < 10) ? "0" + today.getDate() : today.getDate()].join("-"),
        stage: 1,
        approved: -1,
        user_id: 0
    });

    const handleInputChange = ((e) => {
        let field = e.target.name;
        let value = e.target.value
        setDwr({...dwr, [field] : value})
        //console.log(dwr)
    })
    const handleDiscard = (e) => {
        navigate(`/dwr`)
        
    }

    const handleSave = async (e) => {
        e.preventDefault();

        setIsLoading(true);
        
        axios.post(`${apiURL}/api/dwr`, dwr)
        .then(response => {
            console.log(response);
            //handleDiscard(null);
            navigate(`/dwr/${response.data.data.id}`);
            setIsLoading(false);
        })
    
    }

    const handleDelete = (e) => {

    }

  return (
    <>
    <div className="content-heading">
      <div>Create a new Construction Report
      </div>
        <div className="ml-auto">
            <button className="btn btn-sm btn-success" onClick={handleSave}>Save</button> <button className="btn btn-sm btn-secondary" onClick={handleDiscard}>Cancelar</button>
        </div>
    </div>
    <div className="card" id="dailyReportsCard">
      <div className="card-header d-flex">

        </div>
        <div className="card-body">
            
            <FormContext.Provider value={{handleInputChange}}>
                    <form
                noValidate
                autoComplete="off" 
                onSubmit={handleSave}
                >
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group ">
                                <label for="name">Contractor</label>
                                <select className="form-control custom-select" onChange={handleInputChange} value={dwr.contractor_id} name="contractor_id">
                                    <option>Choose a contractor...</option>
                                    <option value="1">TK Solution</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group ">
                                <label for="contract">Contract</label>
                                <select className="form-control custom-select" onChange={handleInputChange} value={dwr.contract} name="contract">
                                    <option>Choose a contract...</option>
                                    <option value="01/23">01/23</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-6">
                            <div className="form-group ">
                                <label for="datetime">Date</label>
                                <input type="text" className="form-control" name="datetime" id="datetime" onChange={handleInputChange} placeholder="Data do RDO" value={dwr.datetime}/>                            
                            </div>
                        </div>
                        {/*<input type='hidden' name="area_id" value={dwr.area_id}/>*/}
                        <div className="col-sm-6 col-lg-6">
                            <div className="form-group ">
                                <label for="area_id">Area</label>
                                <select className="form-control custom-select" onChange={handleInputChange} value={dwr.area_id} name="area_id">
                                    <option>Selecione...</option>
                                    <option value="SLDM.2.1.0.0">SLDM.2.1.0.0</option>
                                </select>
                            </div>
  </div>
                        <div className="col-sm-6 col-lg-6">
                            <div className="form-group ">
                                <label for="discipline_id">Discipline</label>
                                <select className="form-control custom-select" onChange={handleInputChange} value={dwr.discipline_id} name="discipline_id">
                                    <option>Choose discipline...</option>
                                    <option value="1">Civil</option>
                                    <option value="4">Electrical</option>
                                </select>
                            </div>
                        </div>
                    </div>
                        
                    

                </form>
                {isLoading ? (<div className='loading'><span>Loading...</span></div>) : <></>}
                    
                </FormContext.Provider>
        </div>
    </div>
    </>
  )
}

export default DwrForm