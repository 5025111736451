import React, { useState } from 'react'
import AuthContext from './AuthContext';
import {useNavigate} from 'react-router-dom'
import axios from 'axios';

 export const useAuth = () => {
    return React.useContext(AuthContext);
  };

  const apiURL = 'https://api.virtue.fyi'
  //const apiURL = 'http://localhost:8443'

export const CheckToken = (tokenID) => {
  const navigate = useNavigate();

 
  const [token, setToken] = React.useState('')
  const [user, setUser] = React.useState([])
    axios.post(`${apiURL}/api/token`, {tokenID: tokenID})
      .then(response => {
                
        /*setToken(response.data.token);     
        setUser(response.data.user);      
        axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;  
        localStorage.setItem('temp_token', response.data.token)
        navigate('/')*/
      })
      .catch(err => {
        throw err;
      })
}
function AuthProvider({ children }) {
  const navigate = useNavigate();
  
  const [dataProviders, setDataProviders] = React.useState([])
  const [token, setToken] = React.useState([])
  const [user, setUser] = React.useState([])
  
  const getDataProvider = (key) => {
    const dpData = dataProviders.filter(dp => {return dp.id === key});
    if(dpData.length) return dpData[0].data; else return [];
  }

 const handleLogin = async (e) => {
    e.preventDefault(); 
    
    if(!e.target.email.value || !e.target.password.value ) {
      alert('Please, Type your emal and/or password!');
      return;
    }

    const loginPayload = {
      email: e.target.email.value,
      password: e.target.password.value
    }

    axios.post(`${apiURL}/api/login`, loginPayload)
      .then(response => {
                
        setToken(response.data.token);     
        setUser(response.data.user);      
        axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;  
        axios.interceptors.response.use(
          (response) => {
            //console.log(response)
            return response;
          }, 
          async function (error) {
          
          console.log(error.response)
          if (error.response.status === 403 ) {
            alert('Your Session Expired... Redirecting you!')
            window.location = "/"
          } else if( error.response.status === 500) {
            alert('Server Error! You`ll be redirected')
            //window.location = "/"
          }
      
          return Promise.reject(error);
        });
        localStorage.setItem('temp_token', response.data.token)
        axios.get(`${apiURL}/api/providers`)
        .then(response => {
          response.data.data.map(dp => {
            return dp.data = JSON.parse(dp.data)
            
          })
          setDataProviders(response.data.data);
          
        })
      }, (error) => {
        if(error.response.status === 400 || error.response.status === 401) {
          alert(error.response.data.msg)
        }
      })
      .then(response => {
        navigate('/')
      })

    
  }
  const handleLogout = () => {
    setToken(null)
    setUser(null);
    localStorage.removeItem('temp_token');
    delete axios.defaults.headers.common["Authorization"];
  }

  const hasPermission = (module, permission = null) => {
    if(!module) return;
    
    if(user.permissions.hasOwnProperty('admin')) return true;
    
    if(!permission)
    return user.permissions.hasOwnProperty(module);
    else {
      //console.log(user.permissions[module], user.permissions[module].hasOwnProperty(permission))
      return user.permissions[module].find((item) => item === permission)
    }
  }

  const value = {
    token,
    setToken: setToken,
    user,
    apiURL,
    onLogin: handleLogin,
    onLogout: handleLogout,
    getDataProvider,
    setDataProviders,
    hasPermission
  };
  
  return (
    <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider;
