import React, { useContext } from 'react'

function TextInput({field_id, field_label, field_name, field_placeholder, field_value, field_type, rows, fieldChanged}) {
    
  return (
    <div className="form-group ">
        <label htmlFor={field_id}>{field_label}</label>
        { field_type == 'textarea' ? 
        <textarea 
            value={field_value} 
            className="form-control" 
            name={field_name} 
            id={field_id}  
            rows={rows}
            placeholder={field_placeholder ? field_placeholder : ''} 
            onChange={event => fieldChanged(field_name, event.target.value)}/>
        : 
        <input 
            type={field_type ? field_type : 'text'} 
            value={field_value} 
            className="form-control" 
            name={field_name} 
            id={field_id}  
            placeholder={field_placeholder ? field_placeholder : ''} 
            onChange={event => fieldChanged(field_name, event.target.value)}
        />}
        
    </div>
  )
}

export default TextInput