import React, { useEffect, useState } from 'react'
import './Devices.css'
import './Datagrid.css'
import Box from '@mui/material/Box';
import { DataGrid,  GridToolbar  } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './AuthProvider'




const columns = [
  { field: 'id', headerName: 'ID', width: 60, cellClassName: "dataGrid__cell" },
  {
    field: 'equipment',
    headerName: 'Equipment Tag',       
    cellClassName: "dataGrid__cell",
    width: 130
  },
  {
    field: 'equipment_id',
    headerName: 'Tag',     
    cellClassName: "dataGrid__cell",
    width: 110
  },
  {
    field: 'equipment_name',
    headerName: 'Equipment',     
    cellClassName: "dataGrid__cell",
    width:450
  },
  {
    field: 'area',
    headerName: 'Area',   
    cellClassName: "dataGrid__cell",
    width: 60
  },
  {
    field: 'type',
    headerName: 'Type',   
    cellClassName: "dataGrid__cell",
    width: 60
  },
  {
    field: 'loop',
    headerName: 'Loop',   
    cellClassName: "dataGrid__cell",
    width: 60
  },
  {
    field: 'description',
    headerName: 'Description',   
    cellClassName: "dataGrid__cell",
    width: 300
  },
  {
    field: 'man_hours',
    headerName: 'Est. Man Hours',   
    cellClassName: "dataGrid__cell",
    width: 60
  },
  {
    field: 'serial',
    headerName: 'Serial',   
    cellClassName: "dataGrid__cell",
    width: 110
  },
  /*{
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  }*/
];




function Devices() {
  const navigate = useNavigate();
  const { apiURL } = useAuth();
  const [rows, setRows] = useState([]);

  const openDevice = (e) => {
    
  if (!e.id) return;
    navigate(`/devices/${e.id}`)
  }

  useEffect(() => {
    
    axios.get(`${apiURL}/api/devices`)
    .then(res => {
      if(res.status != 200){
        console.log('an error occurred')
        return;
      }
      setRows(res.data.data);
    })
  }, [])

  return (
    <>
    <div className="content-heading">
      <div>Devices</div>
      <div className="ml-auto">
        <button className='btn btn-sm btn-primary' disabled onClick={() => {}}><input hidden accept="image/*" multiple type="file" /> Import</button>
      </div>
    </div>

    <Box  className="devices__box dnone" sx={{ height: 720, width: '100%' }}>  
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[50]}
            rowHeight={30}
            onRowDoubleClick={openDevice}
            
            components={{ Toolbar: GridToolbar }}

            /*checkboxSelection*/
            /*disableSelectionOnClick*/
            /*experimentalFeatures={{ newEditingApi: true }}*/
            
            />
       </Box> 
    
    </>
  )
}

export default Devices