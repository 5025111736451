import React, { useContext } from 'react'
import { FormContext } from '../FormContext'

function TextArea({field_id, field_label, field_name, field_placeholder, field_value}) {
    const { handleInputChange } = useContext(FormContext);
  return (
    <div className="form-group ">
        <label htmlFor={field_id}>{field_label}</label>
        <textarea
            rows="3"
            value={field_value}
            className="form-control" 
            name={field_name} 
            id={field_id}  
            placeholder={field_placeholder ? field_placeholder : ''} 
            onChange={event => handleInputChange(field_name, event)}
        ></textarea>
    </div>
  )
}

export default TextArea