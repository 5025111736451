import React, {useEffect, useState } from 'react'
import './RedLines.css'
import './Datagrid.css'
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import RefreshIcon  from '@mui/icons-material/Refresh';
import axios from 'axios';

import ActionButtons from './ActionButtons';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



function RedLines() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    { field: 'id', headerName: 'ID', type: 'number', width: 60, cellClassName: "dataGrid__cell__center" },
    {
      field: 'filename',
      headerName: 'File',       
      cellClassName: "dataGrid__cell",
      flex: 0.2,
      minWidth: 120
    },
    {
      field: 'description',
      headerName: 'Short description',     
      cellClassName: "dataGrid__cell",
      flex: 0.2,
      minWidth: 120
    },
    
    {
      field: 'actions',
      headerName: 'Actions',   
      cellClassName: "dataGrid__cell",
      width: 100,
      type: 'actions',
      renderCell: params => <ActionButtons { ...{params, handleClickDelete, handleClickOpen}}/>
    }
  ];

  const {token, apiURL} = useAuth();
  const getRedlines = async () => {
    setIsLoading(true);
    const payload = {
      
    }
  
    axios.get(`${apiURL}/api/redlines`, payload)
      .then(response => {                
        setRedlines(response.data.data);        
        setIsLoading(false);
      })
  }
  const [redlines, setRedlines] = useState([]);

  useEffect(() => {
    getRedlines();

  }, [])

  const [open, setOpen] = React.useState(false);
  const [targetId, setTargetId] = useState(null);

  const handleClickDelete = (id) => {
    setOpen(true);
    setTargetId(id);
  };

  const handleClickOpen = (id) => {
      console.log(id)
      navigate(`/redlines/${id}`);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    if(!targetId) return;
    axios.delete(`${apiURL}/api/redlines/${targetId}`)
      .then(response => {                
        setRedlines(redlines.filter(item => { return(item.id != targetId) }))
        setOpen(false);
        setTargetId(null);
      })
  };

  const addNew = () => {
    navigate("/redlines/new")
  }
  const refetch = () => {
    getRedlines();
  }

  return (
    <>
    <div className="content-heading">
      <div>Redlines
        <small>Manage redlines</small>
      </div>
      <div className="ml-auto">
        <button className='btn btn-sm btn-success' onClick={addNew}>Add</button> <button className='btn btn-sm btn-primary' onClick={refetch}><RefreshIcon /> Refresh</button>
      </div>
    </div>
    

        <Box  className="redlines__box" sx={{ height: 720, width: '100%' }}>
          <DataGrid
            rows={redlines}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[50]}
            rowHeight={30}

            components={{ Toolbar: GridToolbar }}
            
            /*checkboxSelection*/
            /*disableSelectionOnClick*/
            /*experimentalFeatures={{ newEditingApi: true }}*/
            
          />
        </Box>
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm deleting redline"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this redline?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleDelete} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
        {isLoading ? (<div className='loading'><span>Loading...</span></div>) : <></>}
    
    </>
  )
}

export default RedLines