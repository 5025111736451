import React, { useContext, useEffect } from 'react'
import './Header.css'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from './AuthProvider'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faBell, faBook, faEnvelope, faExpand, faLock, faMagnifyingGlass, faUser } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { HeaderContext } from './HeaderContext';

function Header({toggleObj}) {
  const headerContext = useContext(HeaderContext);
  const { onLogout, token, user } = useAuth();

   const toggleAside = () => {
        headerContext.toggleMenu()
        console.log('toggling')
   }

  useEffect(() => {
        
  }, [])

  return (
    <>
    <header className="topnavbar-wrapper">
        <nav className="navbar topnavbar">
                <div className="navbar-header">
                        <a className="navbar-brand" href="#/">
                                <div className="brand-logo">
                                        <img className="img-fluid logo" src="./logo_big.png" alt="App Logo"/>
                                </div>
                                <div className="brand-logo-collapsed">
                                        <img className="img-fluid" src="img/logo-single.png" alt="App Logo"/>
                                </div>
                        </a>
                </div>
                <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item">
                                <a onClick={toggleAside} className="nav-link sidebar-toggle d-md-none">
                                        <FontAwesomeIcon icon={faBars} />
                                </a>
                        </li>
                        <li className="nav-item d-none ">
                                <a title="Lock screen" className="nav-link">
                                        <FontAwesomeIcon icon={faLock} />
                                </a>
                        </li>
                </ul>
                <ul className="navbar-nav flex-row">
                        <li className="nav-item">
                                <a className="nav-link" data-search-open="">
                                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                                </a>
                        </li>
                        <li className="nav-item">
                                <a className="nav-link">
                                        <FontAwesomeIcon icon={faUser} />
                                </a>
                        </li>
                        <li className="nav-item d-none">
                                <a className="nav-link" tag="a">
                                        
                                        <FontAwesomeIcon icon={faExpand} />
                                </a>
                        </li>
                        <li className="dropdown-list dropdown nav-item">
                                <a aria-haspopup="true" className="dropdown-toggle-nocaret nav-link" aria-expanded="false">
                                        <FontAwesomeIcon icon={faBell} />
                                        <span className="badge badge-danger d-none">11</span>
                                </a>
                                <div tabindex="-1" role="menu" aria-hidden="true" className="dropdown-menu-right animated flipInX dropdown-menu dropdown-menu-right">
                                        <button type="button" tabindex="0" role="menuitem" className="dropdown-item">
                                                <ul className="list-group">
                                                        <a href="" className="list-group-item-action list-group-item">
                                                                <div className="media">
                                                                        <div className="align-self-start mr-2">
                                                                                <em className="fab fa-twitter fa-2x text-info"></em>
                                                                        </div>
                                                                        <div className="media-body">
                                                                                <p className="m-0">New followers</p>
                                                                                <p className="m-0 text-muted text-sm">1 new follower</p>
                                                                        </div>
                                                                </div>
                                                        </a>
                                                        <a href="" className="list-group-item-action list-group-item">
                                                                <div className="media">
                                                                        <div className="align-self-start mr-2">
                                                                                <FontAwesomeIcon className="fa-2x text-warning" icon="fa-solid fa-list-check" />
                                                                        </div>
                                                                        <div className="media-body">
                                                                                <p className="m-0">New e-mails</p>
                                                                                <p className="m-0 text-muted text-sm">You have 10 new emails</p>
                                                                        </div>
                                                                </div>
                                                        </a>
                                                        <a href="" className="list-group-item-action list-group-item">
                                                                <div className="media">
                                                                        <div className="align-self-start mr-2">
                                                                        <FontAwesomeIcon icon="fa-solid fa-list-check" />
                                                                        </div>
                                                                        <div className="media-body">
                                                                                <p className="m-0">Pending Tasks</p>
                                                                                <p className="m-0 text-muted text-sm">11 pending task</p>
                                                                        </div>
                                                                </div>
                                                        </a>
                                                        <a href="" className="list-group-item-action list-group-item">
                                                                <span className="d-flex align-items-center">
                                                                        <span className="text-sm">More notifications</span>
                                                                        <span className="badge badge-danger ml-auto">14</span>
                                                                </span>
                                                        </a>
                                                </ul>
                                        </button>
                                </div>
                        </li>
                        <li className="nav-item">
                                <a className="nav-link">
                                        <FontAwesomeIcon icon={faBook} />
                                </a>
                        </li>
                </ul>
                <form className="navbar-form" role="search">
                        <div className="form-group">
                                <input className="form-control" type="text" placeholder="Type and hit enter ..."/>
                                <FontAwesomeIcon className='navbar-form-close' icon={faTimesCircle} />
                        </div>
                        <button className="d-none" type="submit">Submit</button>
                </form>
        </nav>
</header>


    
    </>
  )
}

export default Header