import React from 'react'
import TextInput from './TextInput'
import Combo from './Combo';


function FieldGroup({ field, fieldChanged, values}) {
  
    const fields = field.fields;
  


    return (
    <fieldset key={field.field_name}>
        {field.label && <h4>{field.label}</h4>}
        {fields.map((field) => {
           switch (field.component) { 
            case "select":
                return (<Combo
                    key={field.field_name} 
                    field={field}
                    fieldChanged={fieldChanged}
                    values={values[field.field_name]}/>
                     )
            default:
                return (
                    <TextInput
                        key={field.field_name}
                        field_id={field.field_name}
                        field_label={field.label}    
                        field_name={field.field_name}
                        field_type={field.type}
                        fieldChanged={fieldChanged}
                        value={values[field.field_name]}
                    />)
        }
        })}
    </fieldset>
  )
}

export default FieldGroup