import React, { useContext } from 'react'
import './LeftBar.css'
import MenuItem from './MenuItem'

import { useAuth } from './AuthProvider'
import { HeaderContext } from './HeaderContext';

import { faHouse, faGaugeHigh,faUser, faStreetView, faDisplay, faBuilding, faObjectGroup, faFileAlt, faFolder, faClipboardCheck, faBook, faChartBar, faCircleQuestion, faLifeRing, faDollarSign } from '@fortawesome/free-solid-svg-icons';
function LeftBar() {
  const token = useAuth();
  

  const headerContext = useContext(HeaderContext);

  const enabledModules = {
    home: true,
    dashboard: false,
    accounts: token.hasPermission('accounts'),
    clients: token.hasPermission('clients'),
    contractors: token.hasPermission('contractors'),
    projects:token.hasPermission('projects'),
    devices: token.hasPermission('devices'),
    documents: token.hasPermission('documents'),
    fileManager: token.hasPermission('file-manager'),
    redLines: token.hasPermission('redlines'),
    punchList: token.hasPermission('punch-list'),
    resources: token.hasPermission('resources'),
    dwr: token.hasPermission('dwr'),
    journalLog: token.hasPermission('journal-log'),
  }

  return (token?.token?.length) ? (
    <>
    <aside className={`aside-container`}>
    <div className="aside-inner">
      <nav data-sidebar-anyclick-close="" className="sidebar">
        <ul className="sidebar-nav">
          <li className="has-user-block">
            <div id="user-block" className="collapse">
              <div>
                <div className="item user-block">
                  <div className="user-block-picture">
                    <div className="user-block-status">
                      <img className="img-thumbnail rounded-circle" src="img/user/02.jpg" alt="Avatar" width="60" height="60" />
                      <div className="circle bg-success circle-lg"></div>
                    </div>
                  </div>
                  <div className="user-block-info"><span className="user-block-name">Hello, Mike</span><span className="user-block-role">Designer</span></div>
                </div>
              </div>
            </div>
          </li>
          <li className="nav-heading"><span>Main Navigation</span></li>  
               
          {enabledModules.home ? <MenuItem icon={faHouse} title="Home" route="/" /> : ''}
        {enabledModules.dashboard ? <MenuItem icon={faGaugeHigh} title="Dashboard" route="/dashboard" />: ''}
        {enabledModules.accounts ? <MenuItem icon={faStreetView} title="Accounts" route="/accounts" />: ''}
        {enabledModules.clients ? <MenuItem icon={faBuilding} title="Clients" route="/clients" />: ''}
        {enabledModules.contractors ? <MenuItem icon={faUser} title="Contractors" route="/contractors" />: ''}
        {enabledModules.dwr ? <MenuItem icon={faDollarSign} title="Cost Chronicles" route="/dwr" />: ''}
        {enabledModules.projects ? <MenuItem icon={faObjectGroup} title="Projects" route="/projects" />: ''}
        {enabledModules.devices ? <MenuItem icon={faDisplay} title="Devices" route="/devices" />: ''}
        {enabledModules.documents ? <MenuItem icon={faFileAlt} title="Documents" route="/documents" />: ''}
        {enabledModules.fileManager ? <MenuItem icon={faFolder} title="File Manager" route="/file-manager" />: ''}
        {enabledModules.redLines ? <MenuItem icon={faFileAlt} title="Red Lines" route="/redlines" />: ''}
        {enabledModules.punchlist ? <MenuItem icon={faClipboardCheck} title="Punch List" route="/punchlist" />: ''}
        {enabledModules.journalLog ? <MenuItem icon={faBook} title="Journal Log" route="/journal-log" />: ''}
        
        {/*<li className="nav-heading"><span>Help</span></li>
        <hr />
        <MenuItem icon={faLifeRing} title="Help Center" route="/help"/>
  <MenuItem icon={faCircleQuestion} title="Live Support" route="/support"/>*/}
          
        </ul>
      </nav>
    </div>
  </aside>
    
    
    </>
  ) : <></>
  
}

export default LeftBar