import React, {useState, useEffect} from 'react'
import './DeviceDetail.css'
import PropTypes from 'prop-types';
import axios from 'axios';
import { useAuth } from './AuthProvider'
import { useParams, useNavigate, Link } from 'react-router-dom';

import { FormContext } from './FormContext';

/*import { formData } from '../formData';*/

import Element from './form/Element';

import { TextField , Tabs, Tab, Typography, Box, Grid, Button, MenuItem, IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import Input from './form/Input';
import Select from './form/Select';
/*import Form from './form/Form';*/




function TabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
    >
    {value === index && (
      <Box sx={{ p: 3 }}>
      <Typography>{children}</Typography>
      </Box>
      )}
      </div>
      );
    }
    
    TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    };
    
    function a11yProps(index) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }
    
    function DeviceDetail() {
      const qp = useParams();
      const [currentTab, setCurrentTab] = useState('data');
      const [value, setValue] = React.useState(0);
      const [checksheetDrawerVisible, setChecksheetDrawerVisible] = useState(false);
      const [currentChecksheetModel, setCurrentChecksheetModel] = useState();
      
      const handleChange = (event, newValue) => {
        setValue(newValue);
      };
      
      const navigate = useNavigate();
      const { apiURL, getDataProvider} = useAuth();
      const [isLoading, setIsLoading] = useState(false);
      const [elements, setElements] = useState([])
      
      useEffect(() => {
        axios.get('/fields.json')
        .then(res => {
          if(res.status != 200) {
            return;
          }
          setElements(res.data)
          
        })
      }, [])
      
      const [deviceData, setDeviceData] = useState({
        id: '',
        client: '',
        project: '',
        equipment_name: '',
        equipment_id: '',
        area: '',
        type: '',
        loop: '',
        dwg_number: '',
        man_hours: 0,
        serial: '',
        manufacturer: '',
        model_number: '',
        supplier: '',
        system: '',
        subsystem: '',
        service: ''
      });
      const [devicePhotos, setDevicePhotos] = useState([{media_id: '', file: '', thumb: ''}])
      const [deviceFiles, setDeviceFiles] = useState([{id: '', filename: '', realfile: ''}])
      const [deviceChecksheets,setDeviceCheckcheets] = useState([{name: '', label: '', checksheet_id: null}])
      
      const handleInputChange = ((name, e) => {
        const newElements = {...elements}
        newElements.fields.forEach(field =>{
          const {field_type, field_name} = field
          if(field_name === name){
            switch (field_type) {
              case 'checkbox':
              field['field_value'] = e.target.checked;
              break;
              
              default:
              field['field_value'] = e.target.value;
            }
          }
          setElements(newElements)
        });
        //console.log(elements)
        let field = name;
        let value = e.target.value
        setDeviceData({...deviceData, [field] : value})
        //console.log({...deviceData, [field] : value})
      })
      
      
      
      const getDevice = async () => {
        if(!qp.device_id) return;
        
        setIsLoading(true)

        axios.get(`${apiURL}/api/devices/${qp.device_id}`)
        .then(response => {
          
          response.data.data.photos.map(photo => {
            //https://virtue.fyi/demo_dev/app/
            if(photo.file.startsWith('getFile.php'))
              photo.file = `https://virtue.fyi/demo_dev/app/${photo.file}`;

          })

          response.data.data.files.map(file => {
            file.realfile = `https://virtue.fyi/demo_dev/app/getFile.php?file=${file.realfile}`;
          })

          setDeviceData(response.data.data.data[0]); 
          setDevicePhotos(response.data.data.photos)
          setDeviceFiles(response.data.data.files)
          setDeviceCheckcheets(response.data.data.checksheets)
          setIsLoading(false)
          
        })
      }

      const handleCheckSheetItemClick = (id) => {
        setCurrentChecksheetModel(id);
        setChecksheetDrawerVisible(true);

        //console.log(elements[currentChecksheetModel]['fields']);
      }

      const handleToggleChecksheetDrawer = () => {
        setChecksheetDrawerVisible(!checksheetDrawerVisible);
      }
      
      const handleSave = (e) => {
        e.preventDefault();

        setIsLoading(true);
        
        axios.put(`${apiURL}/api/devices/${qp.device_id}`, deviceData)
        .then(response => {
            
            alert(response.data.message)
            //handleDiscard(null);
            //navigate(`/dwr/${response.data.data.id}`);
            setIsLoading(false);
        })
      }

      const handleDiscard = (e) => {
        navigate(`/devices`)
      }
      
      const handleDelete = (e) => {
        
      }
      useEffect(() => {
        getDevice();
      }, [])
      
      return (
        
      <FormContext.Provider  value={{handleInputChange}}>
        <div className="content-heading">
          <div>Device detail
          </div>
          <div className="ml-auto">
              <button className="btn btn-sm btn-success" onClick={handleSave}>Save</button> <button className="btn btn-sm btn-secondary" onClick={handleDiscard}>Discard</button> <button className="btn btn-sm btn-warning d-none" onClick={handleDelete}>Delete</button>
            </div>
        </div>

        <nav className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a data-toggle="tab" role="tab" onClick={() => {setCurrentTab('data')}} className={`nav-link ${(currentTab == 'data') ? " active" : ''}`} aria-current="page">Device Data</a>
          </li>
          <li className="nav-item">
            <a data-toggle="tab" role="tab" onClick={() => {setCurrentTab('photos')}} className={`nav-link ${(currentTab == 'photos') ? " active" : ''}`}>Photos</a>
          </li>
          <li className="nav-item">
            <a data-toggle="tab" role="tab" onClick={() => {setCurrentTab('files')}} className={`nav-link ${(currentTab == 'files') ? " active" : ''}`}>Files</a>
          </li>
          <li className="nav-item">
            <a data-toggle="tab" role="tab" onClick={() => {setCurrentTab('checksheets')}} className={`nav-link ${(currentTab == 'checksheets') ? " active" : ''}`}>Checksheets</a>
          </li>
          <li className="nav-item">
            <a data-toggle="tab" role="tab" onClick={() => {setCurrentTab('measurements')}} className={`nav-link ${(currentTab == 'measurements') ? " active" : ''}`}>Measurements</a>
          </li>
        </nav>
        <div className="tab-content bg-white" id="nav-tabContent">
          <div className={`tab-pane fade ${(currentTab == 'data') ? "show active" : ''}`} id="data" role="tabpanel" aria-labelledby="nav-data-tab" tabindex="0">
            {/*<div className="row">
              <Form formData={formData} />
            </div>*/}

            <div className="row ">
              <div className="col-sm-6 col-lg-4">
                <Input field_name="name" field_id="name" disabled field_label="Client name" field_value={deviceData?.client} />
                <Input field_name="project" field_id="project" disabled field_label="Project" field_value={deviceData?.project} />
                <Input field_name="equipment_name" field_id="equipment_name"  field_label="Equipment name" field_value={deviceData?.equipment_name} />
                <Input field_name="loop" field_id="loop"  field_label="Loop" field_value={deviceData?.loop} />
                <Input field_name="dwg_number" field_id="dwg_number"  field_label="Dwg. No." field_value={deviceData?.dwg_number} />
                <Input field_name="area" field_id="area"  field_label="Area" field_value={deviceData?.area} />
                <Input field_name="equipment_id" field_id="equipment_id"  field_label="Loop Name" field_value={deviceData?.equipment_id} />
                <Input field_name="line" field_id="line"  field_label="Line" field_value={deviceData?.line} />
                <Input field_name="type" field_id="type"  field_label="Type" field_value={deviceData?.type} />
                <Input field_name="serial" field_id="serial"  field_label="Serial No." field_value={deviceData?.serial} />
                <Input field_name="manufacturer" field_id="manufacturer"  field_label="Manufacturer" field_value={deviceData?.manufacturer} />
              </div>
              <div className="col-md-6 col-lg-4">
                <Input field_name="model_number" field_id="model_number"  field_label="Model No." field_value={deviceData?.model_number} />
                <Input field_name="supplier" field_id="supplier"  field_label="Supplier" field_value={deviceData?.supplier} />
                <Input field_name="range" field_id="range"  field_label="Range" field_value={deviceData?.range} />
                <Input field_name="units" field_id="units"  field_label="Units" field_value={deviceData?.units} />
                <Input field_name="man_hours" field_id="man_hours"  field_label="Est. Man Hours" field_value={deviceData?.man_hours} />                
                <Select field_name="power" field_id="power"  field_label="Dev. Power" field_value={deviceData?.power} field_options={[{option_value: 0, option_label: 'N/A'}, {option_value: 120, option_label: '120 VAC'}, {option_value: 24, option_label: '24 VDC'}]} />
                <Select field_name="io_power" field_id="io_power"  field_label="I/O Power" field_value={deviceData?.io_power} field_options={[{option_value: 0, option_label: 'N/A'}, {option_value: 'AI', option_label: 'AI'}, {option_value: 'AO', option_label: 'AO'}, {option_value: 'DI', option_label: 'DI'}, {option_value: 'DO', option_label: 'DO'}]}/>
                <Input field_name="system" field_id="system"  field_label="System" field_value={deviceData?.system} />
                <Input field_name="subsystem" field_id="subsystem"  field_label="Subsystem" field_value={deviceData?.subsystem} />
                <Input field_name="service" field_id="service"  field_label="Service" field_value={deviceData?.service} />
                <Select field_name="checksheet_group_id" field_id="checksheet_group_id"  field_label="Group" field_value={deviceData?.checksheet_group_id} field_options={getDataProvider('checksheet_groups').map(item => ({option_value: item?.id, option_label: item?.name}))} />
                
              </div>     
            </div>       
          </div>
          
          <div className={`tab-pane fade ${(currentTab == 'photos') ? "show active" : ''}`} id="photos" role="tabpanel" aria-labelledby="nav-photos-tab" tabindex="1">
            <a className="btn btn-primary">Upload a photo</a>
            <div className='row'>
              <div className='col'>
              {devicePhotos.map((photo, i) => <a key={i} href={photo.file} target="_blank"><img className="rounded  m-2 ms-0" src={photo.thumb} /></a>)}              
              </div>
            </div>
          </div>
          
          <div className={`tab-pane fade ${(currentTab == 'files') ? "show active" : ''}`} id="files" role="tabpanel" aria-labelledby="nav-files-tab" tabindex="2">
          <a className="btn btn-primary" href="#">Upload a file</a>
            <div className='row'>
            
            <table className="table">
            <thead>
            <tr>
            <th scope="col">#</th>
            <th scope="col">File</th>
            <th className='d-none' scope="col">Action</th>
            </tr>
            </thead>
            <tbody>
            {deviceFiles.map((file, i) => <tr key={i}>
              <th scope="row">{file.id}</th>
              <td><a href={file.realfile} target="_blank">{file.filename}</a></td>
              
              <td className='d-none'>Rename | Delete</td>
              </tr>)}
              
              </tbody>
              </table>
              
              </div>
          </div>
          
          
          <div className={`tab-pane fade ${(currentTab == 'checksheets') ? "show active" : ''}`} id="checksheets" role="tabpanel" aria-labelledby="nav-checksheets-tab" tabindex="3">
            
             {/* CheckSheet Panel */} 
              
            <ul className="list-group">                
              {deviceChecksheets.length && deviceChecksheets.map((checksheet, i) => 
                <li className="list-group-item" onClick={() => {handleCheckSheetItemClick(checksheet.checksheet_id)}}>{checksheet.label}</li>    
                )}
            </ul>

                {/* CheckSheet Drawer */}
                <form className='row mt-2 m-0 deviceDetail__form'>
                <div className={`drawer ${!checksheetDrawerVisible ? 'd-none' : ''}`}>
                    <div className="drawer-full">
                    <div className="drawer-header"><IconButton onClick={handleToggleChecksheetDrawer}> <ArrowBack /></IconButton> Check Sheets</div>
                    <div className="drawer-body">
                      <h3>{(elements && currentChecksheetModel) && elements[currentChecksheetModel].title}</h3>
                      <div className="row">
                      {  (elements && currentChecksheetModel) ? elements[currentChecksheetModel]['fields'].map((field, i) => <Element key={i} field={field} />):null }

                      </div>
                    </div>
                    <div className="drawer-footer d-none"></div>
                  </div>
                </div>
                
                <button type="submit" className="btn btn-primary d-none">Submit</button>
                
                </form>
                
                
          </div>
          <div className={`tab-pane fade ${(currentTab == 'measurements') ? "show active" : ''}`} id="measurements" role="tabpanel" aria-labelledby="nav-measurements-tab" tabindex="4">
            
            <h4>Temperature</h4>
            <span className="text-sm">The instruction of how you want the measurements to be taken goes here.</span>
            <div className="row">
              <div className="col-md-6">
                <div id="measurement-table">
                  <div className="card card-default">
                    <div className="card-header d-flex">
                      Measurements
<div className='ml-auto'>Cº</div>
                    </div>
                    <div className="card-body p-0">
                      <table className="table table-sm">
                        <thead>
                          <th>#</th>
                          <th>15 Min</th>
                          <th>30 Min</th>
                          <th>45 Min</th>
                          <th>60 Min</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td><input name="r1-0" className="form-control form-control-sm"/></td>
                            <td><input name="r1-1" className="form-control form-control-sm"/></td>
                            <td><input name="r1-2" className="form-control form-control-sm"/></td>
                            <td><input name="r1-3" className="form-control form-control-sm"/></td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td><input name="r2-" className="form-control form-control-sm"/></td>
                            <td><input name="r2-" className="form-control form-control-sm"/></td>
                            <td><input name="r2-" className="form-control form-control-sm"/></td>
                            <td><input name="r2-" className="form-control form-control-sm"/></td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td><input name="r3-" className="form-control form-control-sm"/></td>
                            <td><input name="r3-" className="form-control form-control-sm"/></td>
                            <td><input name="r3-" className="form-control form-control-sm"/></td>
                            <td><input name="r3-" className="form-control form-control-sm"/></td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td><input name="r4-" className="form-control form-control-sm"/></td>
                            <td><input name="r4-" className="form-control form-control-sm"/></td>
                            <td><input name="r4-" className="form-control form-control-sm"/></td>
                            <td><input name="r4-" className="form-control form-control-sm"/></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="card-footer d-none">
                      <div className="ml-auto d-inline">
                        <button className="btn btn-success" type="button" name="add-line">
                        Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <hr />
                
          </div>
          
        </div>
        
           
        {isLoading ? (<div className='loading'><span>Loading...</span></div>) : <></>}
        </FormContext.Provider>
            )
          }
          
          export default DeviceDetail