import React, {useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import axios from 'axios';

function ContractorQuantityTable() {
  const navigate = useNavigate();
  const [lines, setLines] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {token, apiURL} = useAuth();
  const qp = useParams();
  
  const getLines = async (contractor, id) => {
    setIsLoading(true);
    const payload = {};
    axios.get(`${apiURL}/api/contractors/${contractor}/lines/${id}`, payload)
    .then(response => {                
        setLines(response.data.data);        
        setIsLoading(false);
    })
  }

  useEffect( () => {
    console.log(qp.id, qp.contract);
      getLines(qp.id, qp.contract);
  }, [])
  return (
    <>
    <div className="content-heading">
      <div>Deliverable Items
      </div>
      <div className="ml-auto">
        <button className='btn btn-sm btn-success' disabled onClick={()=>{}}>Import</button>
      </div>
    </div>
    <div className="card">
        <div className="card-body">
            <div className="d-flex align-items-center  mb-3">
                <h3 className="m-0"></h3>
                <button className="ml-auto btn btn-secondary btn-sm d-none" type="button">Copy this invoice</button>
            </div>
            <div className="table-responsive table-bordered mb-3">
                <table className="table table-sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Item</th>
                            <th>Area</th>
                            <th>Activity</th>
                            <th>Unit</th>
                            <th>Qty</th>
                            <th>Price</th>
                            <th>Discipline</th>
                            <th className="text-right d-none">A&ccedil;&otilde;es</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lines.map(line => (
                            <tr className={line.type == 'wbs' && "bg-gray"}>
                                <td>{line.id}</td>
                                <td>{line.item_number}</td>
                                <td>{line.parent_item}</td>
                                <td>{line.activity}</td>
                                <td>{line.unit}</td>
                                <td>{line.quantity}</td>
                                <td>{line.price}</td>
                                <td>{line.discipline}</td>
                                <td className="text-right d-none"><div className="badge badge-success">Iniciado</div></td>
                                
                                
                            </tr>

                        ))}
                       
                        
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    {isLoading ? (<div className='loading'><span>Loading...</span></div>) : <></>}
    </>
  )
}

export default ContractorQuantityTable