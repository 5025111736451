import React, { useEffect, useState } from 'react'
import './FileManager.css'
import './Datagrid.css'
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from './AuthProvider'

import DescriptionIcon from '@mui/icons-material/Description';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FolderIcon from '@mui/icons-material/Folder';
import Icon from './Icon';
import axios from 'axios';

const iconsMapping = {
  folder: FolderIcon,
  'application/pdf' : PictureAsPdfIcon,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : InsertDriveFileIcon,
  'plain/text' : DescriptionIcon

}

const columns = [
  {
    field: 'name',
    headerName: 'Name',       
    cellClassName: "dataGrid__cell",
    flex: 0.4,
    display: 'flex',
    minWidth: 120,
    renderCell: (cellValues) => {
      return (
        <>
        <Icon IconName={iconsMapping[cellValues?.row.type]} />
        {cellValues.row.name}
        </>
      );
    }
  },
  {
    field: 'type',
    headerName: 'Type',     
    cellClassName: "dataGrid__cell",
    flex: 0.2,
    minWidth: 120
  },
  {
    field: 'size',
    headerName: 'Size',   
    cellClassName: "dataGrid__cell",
    flex: 0.1,
    minWidth: 120
  },
  {
    field: 'author',
    headerName: 'Created by',   
    cellClassName: "dataGrid__cell",
    flex: 0.3,
    minWidth: 120
  },
];

function FileManager() {
  const token = useAuth(); 
  const { apiURL, getDataProvider} = useAuth(); 
  const qp = useParams();
  const [rows, setRows] = useState([]);
  const [folder, setFolder] = useState();
  const [parentFolder, setParentFolder] = useState();
  const navigate = useNavigate();

  const getDir = ((folder_id) => {    
    if(!folder_id) folder_id = "ROOT";
    setFolder(folder_id);
    axios.get(`${apiURL}/api/filemanager/${folder_id}`)
    .then((response) => {
      setRows(response.data.data);
      setParentFolder(response.data.parent_id)
    })
  })

  const handleUpALevelButton = ((e) => {    
    if(!parentFolder) return;
    getDir(parentFolder)
    navigate(`/file-manager/${parentFolder}`)
  })

  useEffect(() => {
    getDir(qp.folder_id)
  },[folder])

  const openItem = (e) => {
    //console.log(e);
  if (!e.id) return;
    if(e.row.is_folder){
      getDir(e.id)
      navigate(`/file-manager/${e.id}`)
    }else{
      
      //window.open(`${apiURL}/api/filemanager/file/${e.row.id}`, '_blank');
      
      fetch(`${apiURL}/api/filemanager/file/${e.row.id}`, { headers : {'Authorization': `Bearer ${token.token}`}} ) // FETCH BLOB FROM IT
        .then((response) => response.blob())
        .then((blob) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
          var _url = window.URL.createObjectURL(blob);
          //window.open(_url, "_blank").focus(); // window.open + focus
          const link = document.createElement('a');
          link.href = _url;
          link.setAttribute(
            'download',
            e.row.name
          );
      
          //document.body.appendChild(link);
          link.click();
          //link.parentNode.removeChild(link);
      }).catch((err) => {
        console.log(err);
      });
    }
  }
  return (
    <>
    <div className="content-heading">
          <div>File Manager</div>
    </div>
    
    <div className="d-block">
      <button className="btn btn-sm btn-primary" disabled><em className="fas fa-folder"></em> New Folder</button> <button className="btn btn-sm btn-success" disabled onClick={() => {}}>Upload a file</button> <button className="btn btn-sm" id="rename" disabled=""><em className="fas fa-edit"></em> Rename</button> <button className="btn btn-sm btn-danger" id="delete" disabled=""><em className="fas fa-trash"></em> Delete</button>
    </div>
    { parentFolder > 0 && (<div className="d-block mt-2">
      <button className="btn btn-sm btn-success" onClick={handleUpALevelButton}><em className="fas fa-folder"></em> Up a level</button>
    </div>)}

        <Box  className="fileManager__box" sx={{ height: 700, width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            rowHeight={50}
            onRowDoubleClick={openItem}
            
            checkboxSelection
            disableSelectionOnClick        
            /*experimentalFeatures={{ newEditingApi: true }}*/
            
            />
        </Box>

    
    </>
  )
}

export default FileManager