import React from 'react'
import TextInput from './TextInput'
import Combo from './Combo';
import FieldGroup from './FieldGroup';
import File from './File';



function MultiColumn({ field, fieldChanged, fileHandler, values, columns}) {
  
    const columnsValue = columns ? (12 / columns) : 12;
    const fields = field.fields;
  


    return (
    <div className='row' key={field.field_name}>
        {field.label && <h4>${field.label}</h4>}   
        {fields.map((field) => {
            switch (field.component) {
                case "field_group":
                    return (<div className={`col-sm-${columnsValue}`}><FieldGroup
                        key={field.field_name} 
                        field={field}
                        fieldChanged={fieldChanged}
                        values={values}/></div>
                            )            
                case "options":
                    return (<div  className={`col-sm-${columnsValue}`}
                        key={field.field_name} 
                        field={field}
                        fieldChanged={fieldChanged}
                        values={values[field.field_name]}>
                            Options
                        </div>)
                case "select":
                    return (<div className={`col-sm-${columnsValue}`}><Combo
                        key={field.field_name} 
                        field={field}
                        fieldChanged={fieldChanged}
                        values={values[field.field_name]}/></div>
                         )
                case "textbox":
                    return (<div className={`col-sm-${columnsValue}`}>
                            <p className="text-sm">{field.content}</p>
                        </div>
                    )
                case "file":
                    return (<div className={`col-sm-${columnsValue}`}><File
                                key={field.field_name}
                                field={field}
                                fieldChanged={fieldChanged}
                        /></div>
                    )
                default:
                    return (
                        <div className={`col-sm-${columnsValue}`}><TextInput
                            key={field.field_name}
                            field_id={field.field_name}
                            field_label={field.label}    
                            field_name={field.field_name}
                            field_type={field.type}
                            fieldChanged={fieldChanged}
                            value={values[field.field_name]}
                        /></div>)
            }
        })}
    </div>
  )
}

export default MultiColumn