import React, {useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPencil, faTrash, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, Modal } from 'react-bootstrap';


function ContractorDetail() {
    const qp = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const {token, apiURL} = useAuth();

    const [showModal, setShowModal] = useState(false);
    const [contactModalData, setContactModalData ] = useState({});

    const handleCloseModal = () => {setShowModal(false); setContactModalData({})};
    const handleShowModal = () => setShowModal(true);

    const handleEditContact = (item) => {
        setContactModalData(item)
        handleShowModal();
        console.log(contactModalData, item)
    }

    const handleContractorSave = () => {
        var payload = {name: 
            contractor.name
        }
        setIsLoading(true);
        if(contractor.id)
            axios.put(`${apiURL}/api/contractors/${contractor.id}`, payload)
            .then(response => {                
                setIsLoading(false);
            })
        else
            axios.post(`${apiURL}/api/contractors`, payload)
            .then(response => {               
                setIsLoading(false);
                navigate(`/contractors/${response.data.data.insertId}`)
            })
    }

    const handleContactSave = () => {
        contractor?.contacts.map(contact => {
            if(contact.id == contactModalData.id){
                var newContact = contactModalData
                console.log(newContact);
                contact = newContact
                
                //setContactModalData({...contractor, "contacts" : contractor.contacts});
                return;
            }
        })
        let newContractor = {...contractor, contacts: {...contractor.contacts}};
        //setContractor(newContractor)
        console.log(newContractor);
        
    }

    const handleInputChange = (e) => {
        let field = e.target.id;
        let value = e.target.value;
        setContractor({...contractor, [field] : value})
    }

    const handleContactInputChange = (e) => {
        let field = e.target.id;
        let value = e.target.value;
        setContactModalData({...contactModalData, [field] : value})
    }

    const [contractor, setContractor] = useState({
            "id": null,
            "name":"",
            "contacts":[
                {
                    "id": null,
                    "name":"",
                    "email":"",
                    "mobile":"",
                    "telephone":"",
                    "occupation":""
                }
            ],
            "contracts":[
                {
                    "id": null,
                    "contract_id":"",
                    "status":"",
                    "actual_end_date":"-",
                    "actual_start_date":"-",
                    "end_date":"-",
                    "start_date":"-"
                }
            ]
        }
    );

    const seeContractLines = (id) => {
        if(!id) return;
        navigate(`/contractors/1/contract/${id}/lines`)
    }

    const handleEditContract = (id) => {
        if(!id) return;
        navigate(`/contractors/1/contract/${id}`)
    }

    const getContract = async (id) => {
        if(id == 'new') return;
        setIsLoading(true);
        const payload = {
        
        }
    
        axios.get(`${apiURL}/api/contractors/${id}`, payload)
        .then(response => {                
            setIsLoading(false);
            setContractor(response.data.data);
        })
    }

    useEffect(() => {
        getContract(qp.id);
        
    }, [qp.id]);

    

  return (
    <>
    <div className="content-heading">
      <div>Contractor
      </div>
      <div className="ml-auto d-none">
        <button className='btn btn-sm btn-success' onClick={()=>{}}>Salvar</button> <button className='btn btn-sm btn-secondary' onClick={()=>{}}>Return</button>
      </div>
    </div>
    <div className="card">
        <div className="card-body">
            <div className="d-flex align-items-center  mb-3">
            
                <form>
                    
                        <div class="input-group">
                        <input type="text" placeholder='Contractor' value={contractor.name} onChange={handleInputChange} id='name' className="form-control"/>
                        <div class="input-group-appepend"><button type="button" onClick={handleContractorSave} class="btn btn-success"><FontAwesomeIcon icon={faCheck} /></button></div>
                        

                        </div>
                        
                    
                </form>
            
                
            </div>
            
            <div className={`row mb-3 pt-2 pb-2 d-none`}>
                <div className='d-flex'>
                    <h4>Contatos</h4>
                    <div className="ml-auto">
                        <button type='button' className="btn btn-sm btn-primary btn-sm"><FontAwesomeIcon icon={faPlusCircle} /></button>
                    </div>
                </div>
                <div className=" table-responsive  mb-3">
                    <table className="table">
                        <thead>
                            <tr>
                                
                                <th className=''>Nome</th>
                                <th className="d-md-table-cell d-none">E-mail</th>
                                <th className="d-md-table-cell d-none">Cargo</th>
                                <th className="d-md-table-cell d-none">Telefones</th>
                                <th className="text-right">Acoes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contractor.contacts?.map(contact => (
                                <tr>
                                    
                                    <td className=''>{contact.name}</td>
                                    <td className="d-md-table-cell d-none">{contact.email}</td>
                                    <td className="d-md-table-cell d-none">{contact.occupation}</td>
                                    <td className="d-md-table-cell d-none">{contact.telephone} {contact.mobile} </td>
                                    <td><button className="btn btn-sm btn-secondary" onClick={() => handleEditContact(contact)}><FontAwesomeIcon icon={faPencil} /></button> <button className="btn btn-sm btn-danger"><FontAwesomeIcon icon={faTrash} /></button></td>
                                    
                                </tr>
                            ))}
                            
                        </tbody>
                    </table>
                </div>

            </div>
            
            <div className={`row mb-3 pt-2 pb-2 ${qp.id == 'new' ? 'd-none' : ''}`}>
                <h4>Contracts</h4>
                <div className="table-responsive table-bordered mb-3">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Contract No.</th>
                                <th className='d-none'>Project</th>
                                <th className="d-md-table-cell d-none">Start Date</th>
                                <th className="d-md-table-cell d-none">End Date</th>
                                <th className="d-md-table-cell d-none">Actual Start Date</th>
                                <th className="d-md-table-cell d-none">Actual End Date</th>
                                <th>Status</th>
                                <th className="text-right"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {contractor.contracts.map(contract => (
                                <tr>
                                    <td>{contract.contract_id}</td>
                                    <td className='d-none'>{contract.project}</td>
                                    <td className="d-md-table-cell d-none">{contract.start_date}</td>
                                    <td className="d-md-table-cell d-none">{contract.end_date}</td>
                                    <td className="d-md-table-cell d-none">{contract.actual_start_date}</td>
                                    <td className="d-md-table-cell d-none">{contract.actual_end_date}</td>
                                    <td className="tet-right">{(contract.status === "started") ? <div className="badge badge-success">Started</div> : <div className="badge badge-Primary">Ended</div>}</td>
                                    <td><Button variant="secondary" size="sm" onClick={(e) => {seeContractLines(contract.contract_id)}}>Deliverables</Button> <Button variant="secondary" size="sm" onClick={() => {handleEditContract(contract.id)}}><FontAwesomeIcon icon={faPencil} /></Button> <Button variant="danger" size="sm" ><FontAwesomeIcon icon={faTrash} /></Button></td>
                                    
                                </tr>
                            ))}
                            
                        </tbody>
                    </table>
                </div>
                <div className="text-right">
                    <Button variant="primary" size="sm">New contract</Button>
                </div>
            </div>
        </div>
    </div>
    <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
        <Modal.Title>Informa&ccedil;&otilde;es do contato</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group className="mb-3" controlId="name">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control type="text" value={contactModalData.name} onChange={handleContactInputChange} placeholder="Nome" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="email">
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control type="email" value={contactModalData.email} onChange={handleContactInputChange}  placeholder="E-mail" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="occupation">
                    <Form.Label>Cargo</Form.Label>
                    <Form.Control type="text" value={contactModalData.occupation} onChange={handleContactInputChange}  placeholder="Cargo" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="telephone">
                    <Form.Label>Telefone</Form.Label>
                    <Form.Control type="text" value={contactModalData.telephone} onChange={handleContactInputChange}  placeholder="(99) 9999-9999" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="mobile">
                    <Form.Label>Celular</Form.Label>
                    <Form.Control type="text" value={contactModalData.mobile} onChange={handleContactInputChange}  placeholder="(99) 9999-9999" />
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
        </Button>
        <Button variant="primary" onClick={handleContactSave}>
            Save
        </Button>
        </Modal.Footer>
    </Modal>
    </>
  )
}

export default ContractorDetail