import React, { useState } from 'react'
import AuthProvider, { useAuth } from './AuthProvider';

import Login from './Login';
import Footer from './Footer';
import Header from './Header';
import LeftBar from './LeftBar'
import MainContainer from './MainContainer';
import { HeaderContext } from './HeaderContext';

function AppWrapper() {
  const { token } = useAuth();
  const [menuState, setMenuState] = useState(0);
  const toggleMenu = () => {
    setMenuState(!menuState);
  }  

  const toggleObj = {
    toggleMenu,
    setMenuState,
    menuState
  }
  //if(!token?.token?.length) return (<Login />); else return (
    
   return ( 
    
      <>
      {!token.length ? <Login /> : 
      
      
        <HeaderContext.Provider value={toggleObj}>
          <div id="app" className={`layout-fixed ${menuState ? 'aside-toggled' : ''}`}>
            <div className="wrapper">
                  <Header />
                  <LeftBar toggleObjs />
                  <MainContainer toggleObjs /> 
                  <Footer /> 
            </div>
          </div>
        </HeaderContext.Provider>
              
      }
      </>
      
    
  )
}

export default AppWrapper