import { faCaretRight, faCommentAlt, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, {useState, useEffect} from 'react'
import  AuthProvider , { useAuth } from './AuthProvider'
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { FormContext } from './FormContext';
import MaoDeObraInput from './MaoDeObraInput';
import NumberFormat from './NumberFormat';


function DwrDetail() {
    const qp = useParams();
    const navigate = useNavigate();
    const  token  = useAuth();
    const { apiURL, getDataProvider} = useAuth();
    const [currentTab, setCurrentTab] = useState('data');
    const [isLoading, setIsLoading] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [canRequestApproval, setCanRequestApproval] = useState(false);
    const [histogramaFields, setHistogramaFields] = useState({maoDeObra: [], equipamentos: []});
    const [histogramData, setHistogramData] = useState({});
    const [filesData, setFilesData] = useState([]);
    const [qQPItems, setQQPItems] = useState([]);
    const [itemNumberInput, setItemNumberInput] = useState('');
    const [selectedQQPItem, setSelectedQQPItem] = useState();
    const [quantityInput, setQuantityInput] = useState('');
    const [dwrData, setDwrData] = useState({
        id: null,
        contractor: '',
        discipline: '',
        area_id: '',
        datetime: '',
        weather_condition: 0,
        area_condition: 0, 
        accidents: 0, 
        activities: '',
        user_id: 0,
        measurement: [{
            dwr_id: null,
            item_number: '',
            area: '',
            activity: '',
            amount: 0,
            accrued: 0,
            unit: '',
            price: 0,
            comments: [
                {
                    datetime: '1970-01-01 00:00:00',
                    user_id: null,
                    comment: ""
                }
            ],
            evidences: [
                {
                    datetime: '1970-01-01 00:00:00',
                    user_id: null,
                    file_url: ""
                }
            ]
        }]
    });

    const handleInputChange = ((e) => {
        let field = e.target.name;
        let value = e.target.value
        setDwrData({...dwrData, [field] : value})
        //console.log(dwr)
    })
    const handleHistogramInputChange = ((e, item) => {
        let field = e.target.name;
        let value = e.target.value;

        if(value > item.contratados) {
            alert("Amount exceeds total available!");
            return;
        }

        let newHistogramData = {...histogramData, [field] : value}
        let newDwrData = {...dwrData, histogram: newHistogramData}
        
        setDwrData(newDwrData)
        setHistogramData(newHistogramData)
        
        
        //console.log(newDwrData);

        
    })

    const getQQPItems = ((area) => {
        //console.log('getQQPItems', area)
        if(!area) return;
        if(!localStorage.getItem(`qqp_${area}_timestamp`) || Math.floor(Date.now() / 1000) - parseInt(localStorage.getItem(`qqp_${area}_timestamp`)) >= 0) {
            axios.get(`${apiURL}/api/dwr/qqp/${area}`)
            .then(response => {
                localStorage.setItem(`qqp_${area}`, JSON.stringify(response.data.data))
                localStorage.setItem(`qqp_${area}_timestamp`, Math.floor(Date.now() / 1000) + 300) //5 minutes
                setQQPItems(response.data.data)
                //fetch QQP records and store in localStorage and set the expire timestamp
            })
        }else{
            setQQPItems(JSON.parse(localStorage.getItem(`qqp_${area}`)));
            //fetch QQP records from localStorage
        }
    })

    const getDwr = async () => {
       
        if(!qp.id) return;
        setIsLoading(true);

        axios.get(`${apiURL}/api/dwr/${qp.id}`)
        .then(response => {          
            
            setDwrData({...dwrData, ...response.data.data})
            setHistogramData(response.data.data.histogram);
            setFilesData(response.data.data.files);
            
            
            if(parseInt(response.data.data.currentApproverId) === token.user.id){
                setCanSave(true);
                if(parseInt(response.data.data.currentApproverIndex) === 0)
                    setCanRequestApproval(false);
                else{
                    setCanRequestApproval(true);
                }
            }else{
                setCanRequestApproval(false);
                setCanSave(false);
            }
            if(response.data.data.approved === 1){
                setIsFinished(true)
            }

            if(response.data.data.area_id) {
                
                    getQQPItems(response.data.data.area_id);

            }
            else{
                console.log("Area nao informada")
            }
            
            //check if there is an entry in localStorage and if it's expired.
            if(!localStorage.getItem('histogramFields_timestamp') || Math.floor(Date.now() / 1000) - parseInt(localStorage.getItem('histogramFields_timestamp')) >= 0) {
                axios.get(`${apiURL}/api/dwr/histograma`)
                .then(response => {
                    localStorage.setItem('histogramFields', JSON.stringify(response.data.data))
                    localStorage.setItem('histogramFields_timestamp', Math.floor(Date.now() / 1000) + 300) //5 minutes
                    setHistogramaFields(response.data.data);
                    //fetch histogram fields and store in localStorage and set the expire timestamp
                })
            }else{
                setHistogramaFields(JSON.parse(localStorage.getItem('histogramFields')));
                //fetch histogram fields from localStorage
            }

            


            setIsLoading(false);
        })
        
    }

    const handleAddMeasurementItem = () => {
        console.log("handling measurement")
        if(!selectedQQPItem || !quantityInput) return;
        let data = {
            cdr_id: qp.id,
            item_number: selectedQQPItem.item_number,
            area: selectedQQPItem.parent_item,
            amount: parseFloat(quantityInput),
            accrued: selectedQQPItem.accrued
        }
        axios.post(`${apiURL}/api/dwr/measurement`, data)
        .then(response => {
            let newDwrData = {...dwrData, measurement: response.data.data}
            setDwrData(newDwrData)
            setSelectedQQPItem({activity: '', item_number: ''})
            setQuantityInput('');
            setItemNumberInput('');
        })
        //addMeasurementItem();
    }

    const handleSave = async (e) => {
        e.preventDefault();

        setIsLoading(true);
        //console.log(dwrData)
        
        axios.put(`${apiURL}/api/dwr/${qp.id}`, dwrData)
        .then(response => {
            console.log(response);
            alert("Data saved successfully")

            setIsLoading(false);
        })
    
    }

    const handleRequestApproval = async (e) => {
        e.preventDefault();

        setIsLoading(true);
        
        axios.put(`${apiURL}/api/dwr/${qp.id}/approve`, dwrData)
        .then(response => {
            navigate(`/dwr`);
        })
    }

    const handleRequestReproval = async (e) => {
        e.preventDefault();

        setIsLoading(true);
        
        axios.put(`${apiURL}/api/dwr/${qp.id}/reject`, dwrData)
        .then(response => {

            navigate(`/dwr`);

        })
    }

    const [selectedFile, setSelectedFile] = useState();

    const handleFileSubmit = async (e) =>{
        e.preventDefault();
        const formData = new FormData();
        formData.append("fileupload", selectedFile)
        //console.log(formData, selectedFile.name)


        try {
            /*const response = await axios({
                method: 'post',
                url: `${apiURL}/api/dwr/upload`,
                data: formData,
                headers: { "Content-Type": 'multipart/form-data' },
            });*/
            axios.post(`${apiURL}/api/dwr/upload/${qp.id}`,  formData , {headers: { "content-type": 'multipart/form-data' }})
            .then((response) => {
                if(response.data.data)
                setFilesData(response.data.data)
                setSelectedFile(null)
            })
        }catch(error) {
            console.log(error)
        }
        return false;
    }

    const handleFileSelect = (e) => {
        setSelectedFile(e.target.files[0]);
        console.log(e.target.files[0])
    }

    const handleOpenFile = (id, fileName) => {
        if(!id || !fileName) return;

        fetch(`${apiURL}/api/dwr/file/${id}`, { headers : {'Authorization': `Bearer ${token.token}`}} ) // FETCH BLOB FROM IT
            .then((response) => response.blob())
            .then((blob) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
            var _url = window.URL.createObjectURL(blob);
            //window.open(_url, "_blank").focus(); // window.open + focus
            const link = document.createElement('a');
            link.href = _url;
            link.setAttribute(
                'download',
                fileName
            );
        
            //document.body.appendChild(link);
            link.click();
            //link.parentNode.removeChild(link);
        }).catch((err) => {
            console.log(err);
        });
    }

    const handleQQPItemChange = ((item_number) => {
        if(!item_number) return;        
        let qQPItem = qQPItems.filter((item) => item.item_number === item_number);
        if(!qQPItem.length) return;
        setQuantityInput('');
        setSelectedQQPItem(qQPItem[0]); 
    })

    const handleQuantityInputChange = ((amount) => {
        if(!selectedQQPItem) return;

        if(selectedQQPItem.accrued + parseFloat(amount) > selectedQQPItem.quantity) {
            alert('Amount exceeds total available!');
            setQuantityInput('');
            return;
        }
    })

    const getUserName =  (id) => {
        if(!id) return;

        let tempUsersList =  getDataProvider('users')
        return tempUsersList.filter((item) =>  { 
             return (item?.id === id);
        } )[0]?.name
    }

    const getDwrPDF = () => {
        let id = qp.id;
        fetch(`${apiURL}/api/dwr/getDWR/${id}`, { headers : {'Authorization': `Bearer ${token.token}`}} ) // FETCH BLOB FROM IT
        .then((response) => response.blob())
        .then((blob) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
          var _url = window.URL.createObjectURL(blob);
          //window.open(_url, "_blank").focus(); // window.open + focus
          const link = document.createElement('a');
          link.href = _url;
          link.setAttribute(
            'download',
            `${id}.pdf`
          );
      
          //document.body.appendChild(link);
          link.click();
          //link.parentNode.removeChild(link);
      }).catch((err) => {
        console.log(err);
      });
    }


    useEffect(() => {//console.log(dwr)
        getDwr();
        
        //console.log(getDataProvider('users'));
    }, [])

  return (
    <>
    <div className="content-heading">
      <div>Cost Chronicles
      </div>
      {!isFinished && <div className="ml-auto ">
        {canSave && <div>
            <button className="btn btn-primary btn-sm" onClick={handleSave} id="saveCdrDataBtn" type="button">Save</button>&nbsp;
            {dwrData.currentApproverIndex === "0" && <button className="btn btn-success btn-sm " onClick={handleRequestApproval} type="button" id="requestApprovalBtn">Request approval</button>}
            {canRequestApproval && <>
                {dwrData.currentApproverIndex !== "0" && <>
                    <button className="btn btn-success btn-sm " onClick={handleRequestApproval}  type="button" id="approveBtn">Approve</button>&nbsp;
                    <button className="btn btn-danger btn-sm " onClick={handleRequestReproval} type="button" id="reproveBtn">Reject</button>
                </>}
            </>}
            
        </div>}
      </div>}
      {isFinished && <div className="ml-auto "><div><button className="btn btn-success btn-sm " onClick={getDwrPDF}  type="button" id="downloadDWR">Download</button></div></div>}
      
    </div>
    <div className="row">
        <FormContext.Provider value={{handleInputChange}}>
        <div className="col-sm-12">
            <div className="card p-2">
                <form method="POST" action="#" name="cdrForm" id="cdrForm">
                    <div role="tabpanel">
                        <div className="row">
                            <input type="hidden" name="id"/>
                            <div className="col-sm-6">
                                <div className="form-group row">
                                    <label className="col-xl-3 col-form-label">Contractor</label>
                                    <div className="col-xl-9  col-form-label">
                                        {dwrData?.contractor}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-xl-3 col-form-label">Discipline</label>
                                    <div className="col-xl-9 col-form-label">
                                        {dwrData?.discipline}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group row">
                                    <label className="col-xl-3 col-form-label">Area</label>
                                    <div className="col-xl-9 col-form-label">
                                        <span id="area_id">{dwrData?.area_id}</span>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-xl-3 col-form-label">Date</label>
                                    <div className="col-xl-9 col-form-label">
                                        {dwrData?.datetime}
                                    </div>
                                </div>
                            </div>
                            <nav aria-label="breadcrumb">
                                Status:
                                <ol className="breadcrumb">
                                    <li className={`breadcrumb-item ${dwrData.meta?.currentApproverIndex === 0 ? 'text-primary' : ''}`}>Started</li>
                                    <li className={`breadcrumb-item ${dwrData.meta?.currentApproverIndex <= dwrData.meta?.approvers.length && dwrData.meta.currentApproverIndex > 0 && dwrData.meta.status !== 1 ? 'text-warning' : ''}`}>
                                        Review {
                                            dwrData.meta?.currentApproverIndex <= dwrData.meta?.approvers.length && dwrData.meta.currentApproverIndex > 0 && dwrData.meta.status !== 1 ? `( ${getUserName(dwrData.meta?.approvers[dwrData.meta.currentApproverIndex])} )` : ''
                                            }
                                    </li>
                                    <li className={`breadcrumb-item ${dwrData.meta?.status === 1 ? 'text-success' : '' }`} aria-current="page">Completed</li>
                                </ol>
                            </nav>
                        </div>
                       
                        <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className={`nav-link ${(currentTab == 'data') ? " active" : ''}`} onClick={() => {setCurrentTab('data')}}  aria-controls="data" role="tab" data-toggle="tab" aria-selected="false">Report Data</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className={`nav-link ${(currentTab == 'measurement') ? " active" : ''}`}  onClick={() => {setCurrentTab('measurement')}} aria-controls="data" role="tab" data-toggle="tab" aria-selected="false">Measurements</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className={`nav-link ${(currentTab == 'histograma') ? " active" : ''}`}  onClick={() => {setCurrentTab('histograma')}} aria-controls="data" role="tab" data-toggle="tab" aria-selected="false">Manpower / Equipment</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className={`nav-link ${(currentTab == 'files') ? " active" : ''}`}  onClick={() => {setCurrentTab('files')}} aria-controls="data" role="tab" data-toggle="tab" aria-selected="false">Files</a>
                            </li>
                        </ul>
                        
                        <div className="tab-content p-0">
                            
                            <div className={`tab-pane ${(currentTab == 'data') ? "show active" : ''} p-2`} id="commentsPanel" role="tabpanel">
                                <div className='row'>
                                    <div className="mb-2">
                                        <span className="text-bold">Weather condition</span>
                                        <select id="weather_condition" name="weather_condition" onChange={handleInputChange} value={dwrData.weather_condition} disabled={!canSave || isFinished} className="form-control custom-select">
                                            <option value="">-</option>
                                            <option value="1">Clear</option>
                                            <option value="2">Rain</option>
                                        </select>
                                    </div>
                                    

                                </div>
                                
                                <div className="mb-2">
                                    <span className="text-bold">Accidents</span>
                                    <select id="accidents" name="accidents" onChange={handleInputChange} value={dwrData.accidents} disabled={!canSave || isFinished} className="form-control custom-select">
                                        <option value="">-</option>
                                        <option value="1">No Accidents</option>
                                        <option value="2">No Time-loss Accident</option>
                                        <option value="3">Time-loss Accident</option>
                                        <option value="4">Goods damage</option>
                                    </select>
                                </div>
                                <span className="text-bold">Activities</span>
                                <textarea className="form-control" name="activities" onChange={handleInputChange} value={dwrData.activities}  disabled={!canSave || isFinished} rows="4"></textarea>
                                
                            </div>
                            <div className={`tab-pane ${(currentTab == 'histograma') ? "show active" : ''} p-2`} id="histogramaPanel" role="tabpanel">
                                {/* Mao-De-Obra */}
                                <div className='row'>
                                    <div className='col-xl-6 row align-content-start'>
                                        <div className='col-xl-6'>
                                            <strong>Direct Labor</strong>
                                        </div>
                                        <div className='col-xl-3'>
                                            <strong>Hired</strong>
                                        </div>
                                        <div className='col-xl-3'>
                                            <strong>Present</strong>
                                        </div>
                                        <div className="form-horizontal mt-2">
                                            {histogramaFields.maoDeObra.filter((item) => {return item.tipo === 'direta'}).map((item) => (
                                                <MaoDeObraInput contratados={item.contratados} key={`${item.id}_${item.tipo}_maodeobra`} name={`${item.id}_${item.tipo}_maodeobra`} label={item.profissional} onChange={(e) => handleHistogramInputChange(e, item)} data={histogramData[`${item.id}_${item.tipo}_maodeobra`]} disabled={!canSave || isFinished || !item.contratados} />
                                            ))}
                                        </div>
                                    </div>
                                    <div className='col-xl-6 row align-content-start"'>
                                    <div className='col-xl-6'>
                                            <strong>Indirect Labor</strong>
                                        </div>
                                        <div className='col-xl-3'>
                                            <strong>Hired</strong>
                                        </div>
                                        <div className='col-xl-3'>
                                            <strong>Present</strong>
                                        </div>
                                        <div className="form-horizontal mt-2">
                                            
                                            {histogramaFields.maoDeObra.filter((item) => {return item.tipo === 'indireta'}).map((item) => (
                                                <MaoDeObraInput contratados={item.contratados} key={`${item.id}_${item.tipo}_maodeobra`} name={`${item.id}_${item.tipo}_maodeobra`} label={item.profissional} onChange={(e) => handleHistogramInputChange(e, item)} data={histogramData[`${item.id}_${item.tipo}_maodeobra`]} disabled={!canSave || isFinished || !item.contratados} />
                                            ))}
                                        </div>
                                    </div>
                                    <div className=' mt-5' />
                                    <div className='col-xl-6 row align-content-start"'>
                                        <div className='col-xl-6'>
                                            <strong>Main Equipments</strong>
                                        </div>
                                        <div className='col-xl-3'>
                                            <strong>Hired</strong>
                                        </div>
                                        <div className='col-xl-3'>
                                            <strong>Present</strong>
                                        </div>                                    
                                        
                                        <div className="form-horizontal mt-2">
                                            
                                            {histogramaFields.equipamentos.filter((item) => {return item.tipo === 'principais'}).map((item) => (
                                                <MaoDeObraInput contratados={item.contratados} key={`${item.id}_${item.tipo}_equipamentos`} name={`${item.id}_${item.tipo}_equipamentos`} label={item.equipamento} onChange={(e) => handleHistogramInputChange(e, item)} data={histogramData[`${item.id}_${item.tipo}_equipamentos`]} disabled={!canSave || isFinished || !item.contratados} />
                                            ))}
                                        </div>
                                    </div>
                                    <div className='col-xl-6 row '>                                    
                                        <div className='col-xl-6 align-content-start'>
                                            <strong>Auxiliary Equipments</strong>
                                        </div>
                                        <div className='col-xl-3'>
                                            <strong>Hired</strong>
                                        </div>
                                        <div className='col-xl-3'>
                                            <strong>Present</strong>
                                        </div>  
                                        
                                        <div className="form-horizontal mt-2">
                                            
                                            {histogramaFields.equipamentos.filter((item) => {return item.tipo === 'auxiliares'}).map((item) => (
                                                <MaoDeObraInput contratados={item.contratados} key={`${item.id}_${item.tipo}_equipamentos`} name={`${item.id}_${item.tipo}_equipamentos`} label={item.equipamento} onChange={(e) => handleHistogramInputChange(e, item)} data={histogramData[`${item.id}_${item.tipo}_equipamentos`]} disabled={!canSave || isFinished || !item.contratados} />
                                            ))}
                                        </div>
                                    </div>
                                    <div className='col-xl-6 row'>      
                                        <div className='col-xl-6 align-content-start'>
                                            <strong>Office</strong>
                                        </div>
                                        <div className='col-xl-3'>
                                            <strong>Hired</strong>
                                        </div>
                                        <div className='col-xl-3'>
                                            <strong>Present</strong>
                                        </div>  

                                        <div className="form-horizontal mt-2">
                                            
                                            {histogramaFields.equipamentos.filter((item) => {return item.tipo === 'escritorio'}).map((item) => (
                                                <MaoDeObraInput contratados={item.contratados} key={`${item.id}_${item.tipo}_equipamentos`} name={`${item.id}_${item.tipo}_equipamentos`} label={item.equipamento} onChange={(e) => handleHistogramInputChange(e, item)} data={histogramData[`${item.id}_${item.tipo}_equipamentos`]} disabled={!canSave || isFinished || !item.contratados} />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className={`tab-pane ${(currentTab == 'measurement') ? "show active" : ''} p-2`} id="measurementPanel" role="tabpanel">
                                <div className={`row p-1 ${(!canSave || isFinished) ? 'd-none' : ''}`} id="addPanel">
                                    <div className="form-group col-sm-2">
                                        <input id="task_code" className="form-control form-control-sm" placeholder="Item" autocomplete="off" list="itemsCode" value={itemNumberInput} onChange={(e) => setItemNumberInput(e.target.value)} onBlur={(e) => handleQQPItemChange(e.target.value)}/>
                                        <datalist id="itemsCode">
                                            {qQPItems.map((item) => (
                                                <option value={item?.item_number}>{item?.item_number} - {item?.activity}</option>
                                            ))}
                                        </datalist>
                                    </div>
                                    <div className="form-group col-sm-5">
                                        <input id="task" value={selectedQQPItem?.activity} className="form-control form-control-sm" readonly=""/>
                                        <small className="text-danger" id="error_message"></small>
                                    </div>
                                    <div className="form-group col-sm-2 text-right">
                                        {selectedQQPItem?.item_number && <span className="small" id="displayAccrued">{ selectedQQPItem?.unit} - {NumberFormat(selectedQQPItem?.price, true)} <br/> Qty: {parseFloat(selectedQQPItem?.quantity).toFixed(2)}, Accrued: {parseFloat(selectedQQPItem?.accrued).toFixed(2)}</span>}
                                    </div>
                                    <div className="form-group col-sm-2 text-right">
                                        <input className="form-control form-control-sm" id="amount" value={quantityInput} onChange={(e) => setQuantityInput(e.target.value)} onBlur={(e) => handleQuantityInputChange(e.target.value)} placeholder="Amount"/>
                                    </div>
                                    <div className="form-group col-sm-1 text-right">
                                        <button className="btn btn-sm btn-success" type="button" disabled={!quantityInput || !selectedQQPItem} onClick={handleAddMeasurementItem} id="cdrItemToListBtn">Add</button>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-sm measurement">
                                        <thead>
                                            <tr>
                                                <th>Item</th>
                                                <th><FontAwesomeIcon icon={faPaperclip}/> <FontAwesomeIcon icon={faCommentAlt}/></th>
                                                <th>Activity</th>
                                                <th>Unit</th>
                                                <th>Amount</th>
                                                <th>Accrued</th>
                                                <th>Completed</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!dwrData?.measurement.length && 
                                            (<tr>
                                                <td colspan='7' className='text-center text-bold'>No item added</td>    
                                            </tr>)}
                                            {dwrData?.measurement?.map(item => (
                                            <>
                                            <tr className=''>
                                                <td><FontAwesomeIcon icon={faCaretRight}/> {item.item_number}</td>
                                                <td>{item?.comments?.length ? <FontAwesomeIcon icon={faCommentAlt}/> : '' }</td>
                                                <td className="" data-toggle="tooltip" data-placement="top" title="" data-original-title="undefined">{item.activity}</td>
                                                <td>{item.unit}</td>
                                                <td>{item.amount}</td>
                                                <td className="text-right">{NumberFormat(item.accrued)}</td>
                                                <td className="text-right">{NumberFormat(parseFloat(item.amount) + parseFloat(item.accrued))}</td>
                                                <td></td>
                                            </tr>
                                            <tr className="detail d-none">
                                                <td></td>
                                                <td colspan="6">
                                                    <div className="row d-none">
                                                        <div className="col-sm-6"><strong>Comments</strong><br/><input className="form-control mt-2" data-id="undefined" name="commentInput"/><button className="btn btn-sm btn-success mt-2" name="addCommentToItem" type="button">Adicionar Comentario</button></div>
                                                        <div className="col-sm-6">
                                                            <strong>Evidencias</strong><br/>
                                                            <div className="custom-file mt-2"><input type="file" className="custom-file-input" data-id="undefined" name="evidenceInput" multiple=""/><label className="custom-file-label" for="customFile">Escolher Arquivo</label></div>
                                                            <br/><button className="btn btn-sm btn-success mt-2" name="attachFileToItem" type="button">Adicionar Evidencia</button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            </>

                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className={`tab-pane ${(currentTab == 'files') ? "show active" : ''} p-2`} id="filesPanel" role="tabpanel">
                                <div className='row'>
                                    <form>
                                    
                                        <div className="mb-2 col-md-6">
                                            <span className="text-bold">Upload a file</span>
                                            {/*<div className="input-group mb-3">
                                                <input type='file' name='fileupload' onChange={handleFileSelect} className='form-control' />
                                                <div className="input-group-append">
                                                    <button onClick={async (e) => {await handleFileSubmit(e)}} disabled={!selectedFile} className='btn btn-primary'>Upload</button>
                                                </div>
                                            </div>*/}
                                            <div className='row'>
                                                <div className="custom-file col-sm-9">
                                                    <input type="file" id="file" name="fileupload" onChange={handleFileSelect}  className="custom-file-input"/>
                                                    <label className="custom-file-label" for="exampleCustomFileBrowser">Choose file</label>
                                                </div>
                                                <div className='col-sm-3'>
                                                    <button onClick={async (e) => {await handleFileSubmit(e)}} disabled={!selectedFile} className='btn btn-primary'>Upload</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <table className='table table-sm'>
                                                <thead>
                                                    <tr>
                                                        <th>File name</th>
                                                        <th>Uploaded at</th>
                                                        <th>User</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!filesData.length && <td className='text-center text-bold' colspan={3}> No files to display</td>}
                                                    {filesData.map((file) => 
                                                        <tr>
                                                            <td><button type='button' className='btn' onClick={() => handleOpenFile(file.id, file.filename)}>{file.filename}</button></td>
                                                            <td>{file.datetime}</td>
                                                            <td>{getUserName(file.user_id)}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </form>
                                    

                                </div>
                                
                            </div>
                            <div className="d-flex">
                                <button className="btn btn-primary mt-2 d-none" id="showCdrHistoryBtn" data-toggle="modal" data-target="#cdrHistoryModal" type="button">History</button>
                                
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div></FormContext.Provider>
    </div>
    {isLoading ? (<div className='loading'><span>Loading...</span></div>) : <></>}
    </>
  )
}

export default DwrDetail