import React from 'react'
import './Punchlist.css'

function Punchlist() {
  return (
    <div className='punchlist'>
        <h3>Punchlist</h3>
    </div>
  )
}

export default Punchlist