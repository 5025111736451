import React, {useEffect, useState } from 'react'
import './Datagrid.css'
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import RefreshIcon  from '@mui/icons-material/Refresh';
import axios from 'axios';

import ActionButtons from './ActionButtons';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function Contractors() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const columns = [
        { field: 'id', headerName: 'ID', type: 'number', width: 60, cellClassName: "dataGrid__cell__center" },
        {
        field: 'name',
        headerName: 'Name',       
        cellClassName: "dataGrid__cell",
        flex: 0.5,
        minWidth: 120
        },
        {
        field: 'actions',
        headerName: 'Actions',   
        cellClassName: "dataGrid__cell",
        width: 100,
        type: 'actions',
        renderCell: params => <ActionButtons { ...{params, handleClickDelete, handleClickOpen}}/>
        }
    ];

    const {token, apiURL} = useAuth();
    const getContractors = async () => {
        setIsLoading(true);
        const payload = {
        
        }
    
        axios.get(`${apiURL}/api/contractors`, payload)
        .then(response => {                
            setContractors(response.data.data);        
            setIsLoading(false);
        })
    }
    const [contractors, setContractors] = useState([]);

  useEffect(() => {
    getContractors();

  }, [])

  const [open, setOpen] = React.useState(false);
  const [targetId, setTargetId] = useState(null);

  const handleClickDelete = (id) => {
    setOpen(true);
    setTargetId(id);
  };

  const handleClickOpen = (id) => {
      console.log(id)
      navigate(`/contractors/${id}`);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    if(!targetId) return;
    axios.delete(`${apiURL}/api/contractors/${targetId}`)
      .then(response => {                
        setContractors(contractors.filter(item => { return(item.id != targetId) }))
        setOpen(false);
        setTargetId(null);
      })
  };

  const addNew = () => {
    navigate("/contractors/new")
  }
  const refetch = () => {
    getContractors();
  }
  return (
    <>
    <div className="content-heading">
      <div>Contractors
        <small>Manage contractors</small>
      </div>
      <div className="ml-auto">
        <button className='btn btn-sm btn-success' onClick={addNew}>Add new</button> <button className='btn btn-sm btn-primary' onClick={refetch}><RefreshIcon /> Refresh</button>
      </div>
    </div>
    

        <Box  className="contractors__box" sx={{ height: 720, width: '100%' }}>
          <DataGrid
            rows={contractors}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[50]}
            rowHeight={30}

            components={{ Toolbar: GridToolbar }}
            
            /*checkboxSelection*/
            /*disableSelectionOnClick*/
            /*experimentalFeatures={{ newEditingApi: true }}*/
            
          />
        </Box>
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmar exclusao de cliente"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tem certeza que deseja excluir essa contratada?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Nao</Button>
          <Button onClick={handleDelete} autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
        {isLoading ? (<div className='loading'><span>Carregando...</span></div>) : <></>}
    
    </>
  )
}

export default Contractors