import React from 'react'
import './SearchPage.css'

function SearchPage() {
  return (
    <div className="searchPage">
        <h4>Search Page</h4>
    </div>
  )
}

export default SearchPage