import axios from 'axios';
import React, {useEffect, useState} from 'react'

import { Bar, BarChart, CartesianGrid, Legend, LineChart, Line, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts'

import { useAuth } from './AuthProvider';

function Home() {
  const  {token, apiURL, hasPermission}  = useAuth();
  const userInfo = useAuth();

  const [rdoTableData, setRdoTableData] = useState([]);
  const [rdoChartData, setRdoChartData] = useState([]);
  const [financeiroChartData, setFinanceiroChartData] = useState([]);

  const rdoBlockType = {
    
    nonRejected: {
      id: 'total_queries',
      bg: 'bg-yellow',
      icon: 'globe-americas',
      vb: '0 0 496 512',
      d: 'M248 8C111.03 8 0 119.03 0 256s111.03 248 248 248 248-111.03 248-248S384.97 8 248 8zm82.29 357.6c-3.9 3.88-7.99 7.95-11.31 11.28-2.99 3-5.1 6.7-6.17 10.71-1.51 5.66-2.73 11.38-4.77 16.87l-17.39 46.85c-13.76 3-28 4.69-42.65 4.69v-27.38c1.69-12.62-7.64-36.26-22.63-51.25-6-6-9.37-14.14-9.37-22.63v-32.01c0-11.64-6.27-22.34-16.46-27.97-14.37-7.95-34.81-19.06-48.81-26.11-11.48-5.78-22.1-13.14-31.65-21.75l-.8-.72a114.792 114.792 0 0 1-18.06-20.74c-9.38-13.77-24.66-36.42-34.59-51.14 20.47-45.5 57.36-82.04 103.2-101.89l24.01 12.01C203.48 89.74 216 82.01 216 70.11v-11.3c7.99-1.29 16.12-2.11 24.39-2.42l28.3 28.3c6.25 6.25 6.25 16.38 0 22.63L264 112l-10.34 10.34c-3.12 3.12-3.12 8.19 0 11.31l4.69 4.69c3.12 3.12 3.12 8.19 0 11.31l-8 8a8.008 8.008 0 0 1-5.66 2.34h-8.99c-2.08 0-4.08.81-5.58 2.27l-9.92 9.65a8.008 8.008 0 0 0-1.58 9.31l15.59 31.19c2.66 5.32-1.21 11.58-7.15 11.58h-5.64c-1.93 0-3.79-.7-5.24-1.96l-9.28-8.06a16.017 16.017 0 0 0-15.55-3.1l-31.17 10.39a11.95 11.95 0 0 0-8.17 11.34c0 4.53 2.56 8.66 6.61 10.69l11.08 5.54c9.41 4.71 19.79 7.16 30.31 7.16s22.59 27.29 32 32h66.75c8.49 0 16.62 3.37 22.63 9.37l13.69 13.69a30.503 30.503 0 0 1 8.93 21.57 46.536 46.536 0 0 1-13.72 32.98zM417 274.25c-5.79-1.45-10.84-5-14.15-9.97l-17.98-26.97a23.97 23.97 0 0 1 0-26.62l19.59-29.38c2.32-3.47 5.5-6.29 9.24-8.15l12.98-6.49C440.2 193.59 448 223.87 448 256c0 8.67-.74 17.16-1.82 25.54L417 274.25z'   
    },
    approved: {
      id: 'queries_blocked_today',
      bg: 'bg-green',
      icon: 'hand-paper',
      vb: '0 0 448 512',
      d: 'M408.781 128.007C386.356 127.578 368 146.36 368 168.79V256h-8V79.79c0-22.43-18.356-41.212-40.781-40.783C297.488 39.423 280 57.169 280 79v177h-8V40.79C272 18.36 253.644-.422 231.219.007 209.488.423 192 18.169 192 40v216h-8V80.79c0-22.43-18.356-41.212-40.781-40.783C121.488 40.423 104 58.169 104 80v235.992l-31.648-43.519c-12.993-17.866-38.009-21.817-55.877-8.823-17.865 12.994-21.815 38.01-8.822 55.877l125.601 172.705A48 48 0 0 0 172.073 512h197.59c22.274 0 41.622-15.324 46.724-37.006l26.508-112.66a192.011 192.011 0 0 0 5.104-43.975V168c.001-21.831-17.487-39.577-39.218-39.993z'   
    },
    inserted: {
      id: 'percentage_blocked_today',
      bg: 'bg-primary',
      icon: 'chart-pie',
      vb: '0 0 544 512',
      d: 'M527.79 288H290.5l158.03 158.03c6.04 6.04 15.98 6.53 22.19.68 38.7-36.46 65.32-85.61 73.13-140.86 1.34-9.46-6.51-17.85-16.06-17.85zm-15.83-64.8C503.72 103.74 408.26 8.28 288.8.04 279.68-.59 272 7.1 272 16.24V240h223.77c9.14 0 16.82-7.68 16.19-16.8zM224 288V50.71c0-9.55-8.39-17.4-17.84-16.06C86.99 51.49-4.1 155.6.14 280.37 4.5 408.51 114.83 513.59 243.03 511.98c50.4-.63 96.97-16.87 135.26-44.03 7.9-5.6 8.42-17.23 1.57-24.08L224 288z'   
    },
    rejected: {
      id: 'domains_being_blocked',
      bg: 'bg-danger',
      icon: 'list-alt',
      vb: '0 0 512 512',
      d: 'M464 480H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h416c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48zM128 120c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm0 96c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm0 96c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm288-136v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12zm0 96v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12zm0 96v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12z'   
    },
  }

  const checksheetsMonthChartData = [
    {
      Period: 'May',
      Completions: 131
    },
    {
      Period: 'June',
      Completions: 2
    },
    {
      Period: 'August',
      Completions: 155
    },
    {
      Period: 'September',
      Completions: 470
    },
    {
      Period: 'October',
      Completions: 296
    },
    {
      Period: 'November',
      Completions: 603
    },
    {
      Period: 'December',
      Completions: 270
    },
    {
      Period: 'January',
      Completions: 371
    },
    {
      Period: 'February',
      Completions: 249
    },
    {
      Period: 'March',
      Completions: 1015
    },
    {
      Period: 'April',
      Completions: 416
    },
  ]

  const checksheetsAreaChartData = [
    { Area: '000', Total: 2, Complete:2 },
    { Area: '200', Total: 8, Complete:4 },
    { Area: '220', Total: 305, Complete:59 },
    { Area: '260', Total: 64, Complete:64 },
    { Area: '300', Total: 1, Complete:1 },
    { Area: '330', Total: 518, Complete:518 },
    { Area: '332', Total: 158, Complete:158 },
    { Area: '335', Total: 554, Complete: 554},
    { Area: '380', Total: 14, Complete: 14},
    { Area: '400', Total: 1, Complete: 1},
    { Area: '410', Total: 20, Complete: 20},
    { Area: '430', Total: 1, Complete: 1},
    { Area: '451', Total: 105, Complete: 105},
    { Area: '452', Total: 194, Complete: 194},
    { Area: '453', Total: 97, Complete: 97},
  ]

  const checksheetsSystemChartData = [
    { System: 'Utilities', Total: 147, Complete:147 },
    { System: 'Air', Total: 30, Complete:30 },
    { System: 'Analyzers', Total: 11, Complete:11 },
    { System: 'Crushing', Total: 289, Complete:110 },
    { System: 'Elution', Total: 689, Complete:689 },
    { System: 'Gold', Total: 100, Complete:100 },
    { System: 'Gravity', Total: 128, Complete:128 },
    { System: 'Ore Cond.', Total: 374, Complete:374 },
    { System: 'Primary Grnd', Total: 426, Complete:426 },
    { System: 'Process', Total: 67, Complete:67 },
    { System: 'Reagent', Total: 577, Complete:577 },
    { System: 'Secondary Grnd', Total: 432, Complete:432 },
    { System: 'Sump', Total: 70, Complete:70 },
    { System: 'Tailings', Total: 101, Complete:101 },
    { System: 'Water', Total: 739, Complete:707 },
    
  ]

  const checksheetsSheetChartData = [
    { Sheet: 'Automation', Total: 1103, Complete:1015 },
    { Sheet: 'MCCs', Total: 30, Complete:30 },
    { Sheet: 'Buckets', Total: 313, Complete:302 },
    { Sheet: 'Motors / Transformer', Total: 288, Complete:274 },
    { Sheet: 'Instruments', Total: 1352, Complete:1240 },
    { Sheet: 'Elements', Total: 287, Complete:287 },
    { Sheet: 'Mechanicals', Total: 103, Complete:103 },
    { Sheet: 'Pumps / Agitators', Total: 254, Complete:254 },
    { Sheet: 'Chute / Phys. Equip.', Total: 300, Complete:289 },
    
    
  ]

  const getRdoTableData = (() => {
    //console.log('getQQPItems', area)
    if(!localStorage.getItem(`dwrTableData_timestamp`) || Math.floor(Date.now() / 1000) - parseInt(localStorage.getItem(`dwrTableData_timestamp`)) >= 0) {
        axios.get(`${apiURL}/api/dwr/getDWRTableData`)
        .then(response => {
            localStorage.setItem(`dwrTableData`, JSON.stringify(response.data))
            localStorage.setItem(`dwrTableData_timestamp`, Math.floor(Date.now() / 1000) + 300) //5 minutes
            setRdoTableData(response.data)
            //fetch QQP records and store in localStorage and set the expire timestamp
        })
    }else{
        setRdoTableData(JSON.parse(localStorage.getItem(`dwrTableData`)));
        //fetch QQP records from localStorage
    }
  })
  const getDWRChartData = (() => {
    //console.log('getQQPItems', area)
    if(!localStorage.getItem(`dwrChartData_timestamp`) || Math.floor(Date.now() / 1000) - parseInt(localStorage.getItem(`dwrChartData_timestamp`)) >= 0) {
        axios.get(`${apiURL}/api/dwr/getDWRChartData`)
        .then(response => {
            localStorage.setItem(`dwrChartData`, JSON.stringify(response.data))
            localStorage.setItem(`dwrChartData_timestamp`, Math.floor(Date.now() / 1000) + 300) //5 minutes
            setRdoChartData(response.data)
            //fetch QQP records and store in localStorage and set the expire timestamp
        })
    }else{
        setRdoChartData(JSON.parse(localStorage.getItem(`dwrChartData`)));
        //fetch QQP records from localStorage
    }
  })
  const getProgressChartData = (() => {
    //console.log('getQQPItems', area)
    if(!localStorage.getItem(`dwrProgressChartData_timestamp`) || Math.floor(Date.now() / 1000) - parseInt(localStorage.getItem(`dwrProgressChartData_timestamp`)) >= 0) {
        axios.get(`${apiURL}/api/dwr/getProgressChartData`)
        .then(response => {
            localStorage.setItem(`dwrProgressChartData`, JSON.stringify(response.data))
            localStorage.setItem(`dwrProgressChartData_timestamp`, Math.floor(Date.now() / 1000) + 300) //5 minutes
            setFinanceiroChartData(response.data)
            //fetch QQP records and store in localStorage and set the expire timestamp
        })
    }else{
        setFinanceiroChartData(JSON.parse(localStorage.getItem(`dwrProgressChartData`)));
        //fetch QQP records from localStorage
    }
  })

  const handleListagemUsuariosBtn = () => {
    const payload = {};
    axios.post(`${apiURL}/api/accounts/reporting/all`, payload)
      .then(response => {
        alert('Done!');
      }) 


  }
  const handleMaoDeObraBtn = () => {
    const payload = {};
    axios.post(`${apiURL}/api/maodeobra/reporting/all`, payload)
      .then(response => {
        alert('Done!');
      }) 


  }
  
  useEffect(() => {
    getRdoTableData();
    getDWRChartData();
    getProgressChartData();
}, [])

  const handleCompletionsReportBtn = () => {
    const payload = {};
    axios.post(`${apiURL}/api/devices/reporting/all`, payload)
      .then(response => {
        alert('Done!');
      }) 


  }
  
  return (
    <>
    <div className="content-heading">
        <div>
          Welcome!
          <small>Welcome, {userInfo.user.name}</small>
        </div>
        <div className="ml-auto d-none"><em className="fas fa-exclamation-triangle text-warning"></em></div>
    </div>
    <div class="row">
      <section className="content">

      {/*<div className="row">
        <div className="col">
          <h3 className="box-title">Análise de Valor Agregado</h3>
          <ValorAgregadoDashboard/>

        </div>
  </div>*/}

        <span className="queries-permitted"></span>
        <span className="queries-blocked"></span>
        <span className="graphs-grid"></span>
        <span className="graphs-ticks"></span>
        
        <div className="row">
          {
            
            rdoTableData.map((item) => <div className="col-lg-3 col-sm-6">
            <div className={`small-box p-2 ${rdoBlockType[item.category].bg} no-user-select`} id={rdoBlockType[item.category].id} title="">
                <div className="inner">
                    <p>{item.label}</p>
                    <h3 className="statistic"><span id="dns_queries_today" className="">{item.soma}</span></h3>
                </div>
                <div className="icon">
                    <svg className={`svg-inline--fa fa-${rdoBlockType[item.category].icon} fa-w-16`} aria-hidden="true" focusable="false" data-prefix="fas" data-icon={rdoBlockType[item.category].icon} role="img" xmlns="http://www.w3.org/2000/svg" viewBox={rdoBlockType[item.category].vb} data-fa-i2svg=""><path fill="currentColor" d={rdoBlockType[item.category].d}></path></svg>
                </div>
            </div>
        </div>)
          }
            
            
        </div>
        
        <div className="row mt-3">
            <div className="col-md-6">
                <div className='card'>
                  <div className='card-header d-flex'>
                        <h3 className="box-title">Work Reports Overview</h3>
                        <div className='ml-auto'>
                          <button onClick={handleCompletionsReportBtn} className="btn btn-secondary" type="button">Generate Completion Report</button>
                        </div>
                  </div>
                  <div className='card-body'>
                    <div className="chart" styled="width: 100%; height: 180px">
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart 
                          
                        data={rdoChartData}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}>
                          <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="added"  fill="#0d2f58" />
                        <Bar dataKey="approved" fill="#f37e02" />
                        <Bar dataKey="rejected" fill="#ea4e4f" />
                          </BarChart>

                      </ResponsiveContainer>

                      
                    </div>
                  </div>
                </div>
                
            </div>
        
        {
          hasPermission('accounts') ?
        
        
            <div className="col-md-6">
                <div className="card" id="clients">
                    <div className="card-header with-border">
                        <h3 className="box-title">Construction Progress (Planned x Actual)</h3>
                    </div>
                    <div className="card-body">
                    <ResponsiveContainer width="100%" height={300}>
                      <LineChart
                        
                        data={financeiroChartData}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="actual" stroke="#0d2f58" />
                        <Line type="monotone" dataKey="planned" stroke="#f37e02" />
                      </LineChart>
                    </ResponsiveContainer>
                  
                        
                    </div>
                    
                    
                </div>
            </div>
        

        : ''}</div>
        
        
        
        
                </section>
    </div>
    {
          hasPermission('devices') ?
    <div className='row'>
      <h3>Construction Reports</h3>
      <div className="col">
      <div className="row" id="chartsContainer">
        <div className="col-xl-12">
          <div className="card card-default">
            <div className="card-header">
                <div className="card-title">Progress</div>
            </div>
            <div className="card-body">
                <div className="chart-bar-Progress">
                <div className="progress-label">94% completed</div>
                  <div className="progress progress-xs mb-3">
                    <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{width: '94%'}}></div>
                  </div>
                  <div className="status-label">
                    Signed Checksheets: 3979<br/>
                    Total Checksheets: 4232
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div className="col-xl-12">
          <div className="card card-default">
            <div className="card-header">
                <div className="card-title">Month</div>
            </div>
            <div className="card-body">
              <ResponsiveContainer width="100%" height={300}>
                <BarChart 
                  
                data={checksheetsMonthChartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}>
                  <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Period" />
                <YAxis />
                <Tooltip />                
                <Bar dataKey="Completions"  fill="#f37e02" />
                
                  </BarChart>

              </ResponsiveContainer>
            </div>
          </div>
        </div>
          <div className="col-xl-12">
              <div className="card card-default">
                <div className="card-header">
                    <div className="card-title">Area</div>
                </div>
                <div className="card-body">
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart 
                      
                    data={checksheetsAreaChartData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="Area" />
                      <YAxis />
                      <Tooltip />                
                      <Bar dataKey="Total" stackId="b"  fill="#0d2f58" />
                      <Bar dataKey="Complete" stackId="a"  fill="#f37e02" />
                    
                    </BarChart>

                  </ResponsiveContainer>
                </div>
              </div>
          </div>
          <div className="col-xl-12">
              <div className="card card-default">
                <div className="card-header">
                    <div className="card-title">Systems</div>
                </div>
                <div className="card-body">
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart 
                      
                    data={checksheetsSystemChartData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="System" />
                      <YAxis />
                      <Tooltip />                
                      <Bar dataKey="Total" stackId="b"  fill="#0d2f58" />
                      <Bar dataKey="Complete" stackId="a"  fill="#f37e02" />
                    
                    </BarChart>

                  </ResponsiveContainer>  
                </div>
              </div>
            </div>
            <div className="col-xl-12">
                <div className="card card-default">
                  <div className="card-header">
                      <div className="card-title">Sheet</div>
                  </div>
                  <div className="card-body">
                    <ResponsiveContainer width="100%" height={300}>
                      <BarChart 
                        
                      data={checksheetsSheetChartData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Sheet" />
                        <YAxis />
                        <Tooltip />                
                        <Bar dataKey="Total" stackId="b"  fill="#0d2f58" />
                        <Bar dataKey="Complete" stackId="a"  fill="#f37e02" />
                      
                      </BarChart>

                    </ResponsiveContainer>
                  </div>
                </div>
            </div>
          </div>
      </div>
    </div>
     : ''}
    </>
  )
}

export default Home