import React, { useContext } from 'react'
import { FormContext } from '../FormContext'

function Input({field_id, field_label, field_name, field_placeholder, field_value, field_type, disabled}) {
    const { handleInputChange } = useContext(FormContext);
  return (
    <div className="form-group ">
        <label htmlFor={field_id}>{field_label}</label>
        <input 
            type={field_type ? field_type : 'text'} 
            value={field_value} 
            className="form-control" 
            name={field_name} 
            id={field_id}  
            disabled={disabled}
            placeholder={field_placeholder ? field_placeholder : ''} 
            onChange={event => handleInputChange(field_name, event)}
        />
    </div>
  )
}

export default Input