import React from 'react'

function Combo({ field, fieldChanged, value }) {
  return (
    <div key={field.field_name} className="form-group ">
        <label for={field.field_name}>{field.label}</label>
        <select 
          className="form-select custom-select" 
          name={field.name} 
          id={field.field_name} 
          onChange={event => fieldChanged(field.field_name, event.target.value)}>
            <option value="">Select...</option>
            {field.options.length && field.options.map((option, i) => <option value={option.value}>{option.text}</option>)}
            
        </select>
    </div>
  )
}

export default Combo