import React from 'react'
import Header from './Header'
import './MainContainer.css'
import { Routes, Route } from 'react-router-dom'

import './ProtectedRoute'

import Login from './Login'

import Home from './Home'
import SearchPage from './SearchPage'
import Dashboard from './Dashboard'
import Accounts from './Accounts'
import Clients from './Clients'
import Contractors from './Contractors'
import Projects from './Projects'
import Devices from './Devices'
import Documents from './Documents'
import FileManager from './FileManager'
import RedLines from './RedLines'
import Punchlist from './Punchlist'
import Dwr from './Dwr'
import JournalLog from './JournalLog'
import DeviceDetail from './DeviceDetail'
import AccountDetail from './AccountDetail'
import MeasurementList from './MeasurementList'

import NotAllowed from './NotAllowed'
import NoMatch from './NoMatch'

import Help from './Help'
import LiveSupport from './LiveSupport'
import ProtectedRoute from './ProtectedRoute'
import { useAuth } from './AuthProvider'
import ClientDetail from './ClientDetail'
import ContractorDetail from './ContractorDetail'
import ContractorQuantityTable from './ContractorQuantityTable'
import RedLineDetail from './RedLineDetail'
import DwrDetail from './DwrDetail';
import DwrForm from './DwrForm';
import Contract from './Contract'




function MainContainer({toggleObj}) {
    const token = useAuth()
        {
            
            return(
                <section className="section-container">
                    <div>
                        <div>
                            <div className="content-wrapper">
                                                <Routes>                        
                                                        <Route path="/" element={
                                                            <Home />
                                                        } />
                                                        <Route index element={<Home />} />
                                                        
                                                        <Route path="/search/:searchTerm" element={
                                                            <ProtectedRoute >
                                                                <SearchPage />
                                                            </ProtectedRoute>
                                                        } />
                                                        <Route path="/dashboard" element={
                                                            <ProtectedRoute >
                                                                <Dashboard />
                                                            </ProtectedRoute>
                                                        } />
                                                        <Route path="/accounts" element={
                                                            <ProtectedRoute >
                                                                <Accounts />
                                                            </ProtectedRoute>
                                                        } />
                                                        <Route path="/accounts/:id" element={
                                                            <ProtectedRoute >
                                                                <AccountDetail />
                                                            </ProtectedRoute>
                                                        } />
                                                        <Route path="/accounts/new" element={
                                                            <ProtectedRoute >
                                                                <AccountDetail />
                                                            </ProtectedRoute>
                                                        } />
                                                        <Route path="/clients" element={
                                                            <ProtectedRoute >
                                                                <Clients />
                                                            </ProtectedRoute>} />
                                                        <Route path="/clients/:id" element={
                                                            <ProtectedRoute >
                                                                <ClientDetail />
                                                            </ProtectedRoute>
                                                        } />
                                                        <Route path="/clients/new" element={
                                                            <ProtectedRoute >
                                                                <ClientDetail />
                                                            </ProtectedRoute>
                                                        } />
                                                        <Route path="/contractors" element={
                                                            <ProtectedRoute >
                                                                <Contractors />
                                                            </ProtectedRoute>} />
                                                        <Route path="/contractors/:id" element={
                                                            <ProtectedRoute >
                                                                <ContractorDetail />
                                                            </ProtectedRoute>} />
                                                            <Route path="/contractors/:id/contract/:contract" element={
                                                            <ProtectedRoute >
                                                                <Contract />
                                                            </ProtectedRoute>} />
                                                        <Route path="/contractors/:id/contract/:contract/lines" element={
                                                            <ProtectedRoute >
                                                                <ContractorQuantityTable />
                                                            </ProtectedRoute>} />
                                                        <Route path="/projects" element={<Projects />} />
                                                        <Route path="/devices" element={<Devices />} />
                                                        <Route path="/devices/:device_id" element={<DeviceDetail />} />
                                                        <Route path="/documents" element={<Documents />} />
                                                        <Route path="/file-manager" element={
                                                            <ProtectedRoute>
                                                                <FileManager />
                                                            </ProtectedRoute>
                                                        } />
                                                        <Route path="/file-manager/:folder_id" element={
                                                            <ProtectedRoute>
                                                                <FileManager />
                                                            </ProtectedRoute>
                                                        } />
                                                        <Route path="/redlines" element={
                                                            <ProtectedRoute >
                                                                <RedLines />
                                                            </ProtectedRoute>
                                                        } />
                                                        <Route path="/redlines/:id" element={
                                                            <ProtectedRoute >
                                                                <RedLineDetail />
                                                            </ProtectedRoute>
                                                        } />
                                                        <Route path="/punchlist" element={<Punchlist />} />
                                                        <Route path="/measurement" element={
                                                            <ProtectedRoute>
                                                                <MeasurementList />
                                                            </ProtectedRoute>
                                                        } />
                                                        <Route path="/dwr" element={
                                                            <ProtectedRoute>
                                                                <Dwr />
                                                            </ProtectedRoute>
                                                        } />
                                                        <Route path="/dwr/:id" element={
                                                            <ProtectedRoute>
                                                                <DwrDetail />
                                                            </ProtectedRoute>
                                                        } />
                                                        <Route path="/dwr/new" element={
                                                            <ProtectedRoute>
                                                                <DwrForm />
                                                            </ProtectedRoute>
                                                        } />
                                                        <Route path="/dwr" element={<Dwr />} />
                                                        <Route path="/journal-log" element={<JournalLog />} />
                                                        <Route path="/help" element={<Help />} />
                                                        <Route path="/support" element={<LiveSupport />} />
                                                        <Route path="/403" element={<NotAllowed />} />
                                                        <Route path="*" element={<NoMatch />} />
                                                        {/* <Route path="/" element={< />} /> */}
                                                </Routes>
                                {/*<div className="content-heading">
                                    Page Title
                                    <div className="ml-auto d-none"><em className="fas fa-exclamation-triangle text-warning"></em></div>
                                </div>
                                <div className="row">
                                    
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </section>
            )}
            
        
            
        }
        
        export default MainContainer