import React, { useEffect, useState } from 'react'
import NumberFormat from './NumberFormat';
import axios from 'axios';
import  AuthProvider , { useAuth } from './AuthProvider'


function MeasurementList() {
    var cost_sum = 0;
    
    const [measurementData, setMeasurementData] = useState([]);
    const [startDate, setStartDate] = useState('2021-05-20');
    const [endDate, setEndDate] = useState('2021-06-20');
    const [area, setArea] = useState();

    const { apiURL } = useAuth();
    const fetchMeasurementData = ({startDate, endDate, area = ''}) => {
      if(!startDate || !endDate ) return;
        
            axios.post(`${apiURL}/api/dwr/measurements`, {startDate, endDate, area})
            .then(response => {
                setMeasurementData(response.data.data)
                //fetch QQP records and store in localStorage and set the expire timestamp
            })
        
    }

    const handleFetchMeasurements = () => {
      if(!startDate || !endDate) return;

      fetchMeasurementData({
        startDate,
        endDate, 
        area
      })
    }
    

  return (
    <>
    <div className="content-heading">
      <div>Measurement records
      </div>
      
    </div>
    <div className="row">
       
            <div className="col-sm-3 col-lg-3">
                <div className="form-group ">
                    <label for="startDate">Start Date</label>
                    <input type="text" className="form-control" name="startDate" id="startDate" onChange={(e) => {setStartDate(e.target.value)}} value={startDate} placeholder="Start Date"/>                            
                </div>
            </div>
            <div className="col-sm-3 col-lg-3">
                <div className="form-group ">
                    <label for="endDate">End Date</label>
                    <input type="text" className="form-control" name="endDate" id="endDate" onChange={(e) => {setEndDate(e.target.value)}} value={endDate} placeholder="End Date"/>                            
                </div>
            </div>
            <div className="col-sm-3 col-lg-3 d-none">
                <div className="form-group ">
                        <label for="area_id">Area</label>
                        <select className="form-control custom-select" onChange={(e) => {setArea(e.target.value)}} value={area} name="area_id">
                            <option>Select Area...</option>
                            <option value="SLDM.0.0.0.0">SLDM.0.0.0.0</option>
                            <option value="SLDM.2.0.0.0">SLDM.2.0.0.0</option>
                            <option value="SLDM.2.7.4.5">SLDM.2.7.4.5</option>
                            <option value="SLDM.2.3.3.5">SLDM.2.3.3.5</option>
                            <option value="SLDM.2.4.0.0">SLDM.2.4.0.0</option>
                            <option value="SLDM.2.9.5.6">SLDM.2.9.5.6</option>
                            <option value="SLDM.1.3.6.0">SLDM.1.3.6.0</option>
                            <option value="SLDM.2.5.1.3">SLDM.2.5.1.3</option>
                            <option value="SLDM.2.5.1.1">SLDM.2.5.1.1</option>
                            <option value="SLDM.2.3.3.1">SLDM.2.3.3.1</option>
                            <option value="SLDM.2.4.7.0">SLDM.2.4.7.0</option>
                            <option value="SLDM.2.3.3.0">SLDM.2.3.3.0</option>
                            <option value="SLDM.2.4.6.0">SLDM.2.4.6.0</option>
                            <option value="SLDM.1.3.3.0">SLDM.1.3.3.0</option>
                            <option value="SLDM.2.4.9.0">SLDM.2.4.9.0</option>
                            <option value="SLDM.2.5.9.0">SLDM.2.5.9.0</option>
                            <option value="SLDM.2.4.5.2">SLDM.2.4.5.2</option>
                            <option value="SLDM.2.6.1.0">SLDM.2.6.1.0</option>
                            <option value="SLDM.2.4.5.3">SLDM.2.4.5.3</option>
                            <option value="SLDM.2.6.0.0">SLDM.2.6.0.0</option>
                            <option value="SLDM.2.1.0.0">SLDM.2.1.0.0</option>
                            <option value="SLDM.2.7.4.0">SLDM.2.7.4.0</option>
                            <option value="SLDM.2.4.5.1">SLDM.2.4.5.1</option>
                            <option value="SLDM.2.4.6.6">SLDM.2.4.6.6</option>
                            <option value="SLDM.2.4.5.4">SLDM.2.4.5.4</option>
                            <option value="SLDM.2.6.1.3">SLDM.2.6.1.3</option>
                            <option value="SLDM.2.6.2.0">SLDM.2.6.2.0</option>
                            <option value="SLDM.2.5.3.0">SLDM.2.5.3.0</option>
                            <option value="SLDM.2.4.7.1">SLDM.2.4.7.1</option>
                            <option value="SLDM.2.2.2.0">SLDM.2.2.2.0</option>
                            <option value="SLDM.1.3.5.0">SLDM.1.3.5.0</option>
                            <option value="SLDM.2.3.3.2">SLDM.2.3.3.2</option>
                            <option value="SLDM.1.0.0.0">SLDM.1.0.0.0</option>
                            <option value="SLDM.2.5.7.0">SLDM.2.5.7.0</option>
                        </select>
                    </div>
                </div>
            <div className="col-sm-3 col-lg-3">
                <div className="form-group ">
                <label for="filtro">&nbsp;</label>
                    <button onClick={handleFetchMeasurements} className='btn btn-sm btn-success btn-block'>Search</button>
                </div>
            </div>
            
    </div>
    <div className="card" id="dailyReportsCard">
      <div className="card-header d-flex">
    
      </div>
      <div className="card-body p-0 overflow-auto">
        <table className="table table-sm text-sm">
          <thead>
            <tr>
              
              <th className="w-auto">Area</th>
              <th className="w-auto" style={{minWidth: '75px'}}>Date</th>
              <th className="w-auto">Code</th>
              <th className="w-auto">Activity</th>
              <th className="w-auto">Unit</th>
              <th className="w-auto text-right">Actual</th>
              <th className="w-auto text-right">Accrued</th>
              <th className="w-auto text-right">Price</th>
              <th className="w-auto text-right">Cost</th>
            </tr>
          </thead>
          <tbody>
          {measurementData.map(line => {
            let cost = line.price * line.amount
            cost_sum += cost;
            return (<tr id="reportEl_">
             
              <td>{line.area}</td>
              <td>{line.date}</td>
              <td>{line.item_number}</td>
              <td>{line.activity}</td>
              <td>{line.unit}</td>
              <td className='text-right'>{NumberFormat(line.amount)}</td>
              <td className='text-right'>{NumberFormat(line.accrued)}</td>
              <td className='text-right'>{NumberFormat(line.price, true)}</td>
              <td className='text-right'><strong>{NumberFormat(cost, true)}</strong></td>
            </tr>)

          })}
          <tr>
            <td className="text-right" colspan="8"><strong>Total</strong></td>
            <td className="text-right"><strong>{NumberFormat(cost_sum, true)}</strong></td>
          </tr>
            
          </tbody>
        </table>
      </div>
    </div> 
    </>
  )
}

export default MeasurementList