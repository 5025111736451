import React, { useContext } from 'react'
import { FormContext } from '../FormContext'

function QASelect({field_id, field_label, field_name, field_value, field_options}) {
    const { handleInputChange } = useContext(FormContext);
  return (
    <div class="col-lg-8 col-md-12">
        <div className="form-group mb-2">
            <label htmlFor={field_id}>{field_label}</label>
            <select 
                value={field_value} 
                className="form-select custom-select custom-select-sm" 
                name={field_name} 
                id={field_id}
                onChange={event => handleInputChange(field_name, event)}
            >
                <option value="">Select...</option>
                {field_options.length && field_options.map((option, i) => <option value={option.option_value}>{option.option_label}</option>)}
            </select>
        </div>
    </div>
  )
}

export default QASelect