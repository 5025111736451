import React, { useEffect, useState } from 'react'
import { useAuth } from './AuthProvider'
import Form from './form/Form'
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { FormContext } from './FormContext';

import moment from 'moment';


function RedLineDetail() {
    const navigate = useNavigate();
    const { apiURL, getDataProvider} = useAuth();

    const [isLoading, setIsLoading] = useState(false);

    const [formValues, setFormValues] = useState([]);


    const [uploadFormVisible, setUploadFormVisible] = useState(false);
    
    const [redline, setRedline] = useState([{
        id: null,
        filename: '',
        description: '',
        filepath: '',
        author: '',
        created_on: '',
        parent_id: null,
        is_latest: 0,
        is_approved: 0,
        rev: '',
        author_name: '', 
        latest_rev: null,
        versions: 0

    }]);

    const formData = [
      {
        component: "multi_column",
        columns: 3,
        field_name: "device_fields",
        fields: [
          {
            component: 'file',
            label: 'Upload a file',
            field_name: 'file'
          },
          {
            component: 'text',
            label: 'File name',
            field_name: 'filename'
          },
          {
            component: 'text',
            label: 'Revision',
            field_name: 'rev'
          }
        ]
      },
      {
        component: 'text',
        type: 'textarea',
        rows: 5,
        label: 'Description',
        field_name: 'Description'
      }
    ];
  
  const qp = useParams();

    const getRedline = async () => {
        if(!qp.id || qp.id === 'new') {
          setUploadFormVisible(true);
          return;
        } 
        setIsLoading(true);

        axios.get(`${apiURL}/api/redlines/${qp.id}`)
        .then(response => {          
          //console.log(response.data.data)
            setRedline(response.data.data); 
            setIsLoading(false);
        })
    }

    const handleSave = (formData) => {
      var form_data = new FormData();

      for ( var key in formData ) {
          form_data.append(key, formData[key]);
      }
      console.log(form_data, formData)
      return;
      if(qp.id != 'new')
        axios.put(`${apiURL}/api/redlines/${qp.id}`, formData,  {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        })
        .then(response => {
          console.log(response)
        })
      else{
        axios.post(`${apiURL}/api/redlines/`, formData,  {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          console.log(response)
        })
      }
    }

    const handleDiscard = (e) => {
        navigate(`/redlines`)
    }
    const handleUploadRevisedDocument = (e) => {
      setUploadFormVisible(true);
    }
    const handleCloseUploadRevisedDocument = (e) => {
      setUploadFormVisible(false);
    }
    
    const handleDelete = (e) => {

    }
  useEffect(() => {
    getRedline();
    
  }, [])
  return (
    <>
    
    <div className="content-heading">
      <div>Redline
        
      </div>
      <div className="ml-auto">
      <button className={`btn btn-sm btn-success ${!uploadFormVisible ? 'd-none' : ''}`} type='button'>Upload Revision</button> <button className={`btn btn-sm btn-danger ${!uploadFormVisible ? 'd-none' : ''}`} type='button' onClick={handleCloseUploadRevisedDocument}>Cancel</button> <button className={`btn btn-sm btn-success ${uploadFormVisible ? 'd-none' : ''}`} type='button' onClick={handleUploadRevisedDocument}>Upload a revised document</button> <button className={`btn btn-sm btn-secondary ${uploadFormVisible ? 'd-none' : ''}`} onClick={handleDiscard}>Discard changes</button> <button className={`btn btn-sm btn-warning ${uploadFormVisible ? 'd-none' : ''}`} onClick={handleDelete}>Delete</button>
        </div>
    </div>

    <div className={`row ${!uploadFormVisible ? 'd-none' : ''}`}>
        <FormContext.Provider value={{ formValues, setFormValues }}>
          <Form formData={formData} setFormValues={setFormValues}/>
          <div className="col">
            <button className="btn btn-sm btn-success"  onClick={() => {handleSave(formValues)}}>Save</button>
          </div>
        </FormContext.Provider>
          
      </div>

    <div className={`row ${uploadFormVisible ? 'd-none' : ''}`}>
               <div className="col-sm-12">
               <div className="card">
                <div className="card-body">
                    
                    <h6 className="card-title">Changes history</h6>
                    <div id="content">
                        <ul className="timeline__custom">
                            { redline.map((item) => (
                              <li className="event" data-date={moment(item.created_on).format('MMM Do YYYY h:mm:ss a')}>
                                  <span className="text-sm">{item.author_name}</span> 
                                  <h3 className="d-flex">
                                      <strong>REV {item.rev}</strong>
                                      <a href={`file://${item.filepath}`} className="p-1 ml-auto btn btn-primary btn-sm">
                                          <em className="fas fa-cloud-download-alt"></em> Download
                                      </a>
                                      
                                  </h3>
                                  { item.is_approved ? <div className="badge bg-success">Approved</div> : ''}
                                  { !item.is_approved ? <div className="badge bg-gray-light">Not approved</div> : ''}
                                  <p>
                                    {item.description}
                                  </p>
                                  
                              </li>
                            )) }                            
                        </ul>
                    </div>
                </div>
            </div>
          </div>
    </div>
    </>
    
  )
}

export default RedLineDetail