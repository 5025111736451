import React, { useEffect, useState } from 'react'
import './ClientDetail.css'
import axios from 'axios';
import  AuthProvider , { useAuth } from './AuthProvider'
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Box, FormControl, Select, InputLabel, MenuItem, OutlinedInput, TextField } from '@mui/material';
import Input from './form/Input';
import { FormContext } from './FormContext';


function ClientDetail() {
    const navigate = useNavigate();
    const { apiURL, getDataProvider} = useAuth();

    const [isLoading, setIsLoading] = useState(false);
    
    const [client, setClient] = useState({
        id: null,
        name: '',        
        projects: ''
    });

  const handleInputChange = ((e) => {
    let field = e.target.name;
    let value = e.target.value
    setClient({...client, [field] : value})
  })
  
  const qp = useParams();

    const getClient = async () => {
        if(!qp.id) return;
        setIsLoading(true);

        axios.get(`${apiURL}/api/clients/${qp.id}`)
        .then(response => {          
            setClient(response.data.data[0]); 
            setIsLoading(false);
        })
    }

    const handleDiscard = (e) => {
        navigate(`/clients`)
    }

    const handleSave = async (e) => {
      e.preventDefault();
      if(!client.name) return;

      setIsLoading(true);

      if(client.id){
        axios.put(`${apiURL}/api/clients/${client.id}`, client)
        .then(response => {
          console.log(response);
          handleDiscard(null);
        })
      }else{
        axios.post(`${apiURL}/api/clients`, client)
        .then(response => {
          console.log(response);
          handleDiscard(null);
          
        })
      }
      setIsLoading(false);
    }
    const handleDelete = (e) => {

    }
  useEffect(() => {
    getClient();
    
  }, [])
  return (
    <>
    <FormContext.Provider value={{handleInputChange}}>
    <div className="content-heading">
      <div>Client
        <small>Edit client information</small>
      </div>
      <div className="ml-auto">
          <button className="btn btn-sm btn-success" onClick={handleSave}>Save</button> <button className="btn btn-sm btn-secondary" onClick={handleDiscard}>Discard</button> <button className="btn btn-sm btn-warning" onClick={handleDelete}>Delete</button>
        </div>
    </div>
    
        

        <form
      noValidate
      autoComplete="off" 
      onSubmit={handleSave}
      >
        <div className="row">
          <div className="col-sm-6 col-lg-4">
            <Input field_name="name" field_id="name" field_label="Client name" field_value={client?.name} />
          </div>
        </div>
            
        

      </form>
      {isLoading ? (<div className='loading'><span>Loading...</span></div>) : <></>}
        
      </FormContext.Provider>
      
    </>
  )
}

export default ClientDetail