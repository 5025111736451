import React, { useState } from 'react'
import "./Calendar.css";
import { format, startOfWeek, addDays, addMonths, subMonths, startOfMonth, endOfMonth, endOfWeek, parse, isSameMonth, isSameDay } from "date-fns"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudRain, faSun, faBolt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import axios from 'axios';


function Calendar( {data} ) {
    const navigate = useNavigate();
    const {token, apiURL} = useAuth();
    const [state, setState] = useState({
        currentMonth: new Date(),
        selectedDate: new Date()
    });

    let dataOld = {
        "2021-09-01": {
            "id": 4978,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-02": {
            "id": 4996,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-03": {
            "id": 5021,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-04": {
            "id": 5042,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-08": {
            "id": 5062,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-05": {
            "id": 5063,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-06": {
            "id": 5064,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-09": {
            "id": 5150,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-10": {
            "id": 5165,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-12": {
            "id": 5182,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-11": {
            "id": 5183,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-13": {
            "id": 5201,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-14": {
            "id": 5225,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-15": {
            "id": 5241,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-16": {
            "id": 5293,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-07": {
            "id": 5312,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-17": {
            "id": 5328,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-18": {
            "id": 5403,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-19": {
            "id": 5431,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-20": {
            "id": 5432,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-21": {
            "id": 5471,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-24": {
            "id": 5475,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-22": {
            "id": 5500,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-23": {
            "id": 5520,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-25": {
            "id": 5572,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-26": {
            "id": 5584,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-27": {
            "id": 5585,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-28": {
            "id": 5607,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-29": {
            "id": 5624,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-09-30": {
            "id": 5642,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-01-03": {
            "id": 7474,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-01-04": {
            "id": 7494,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-01-05": {
            "id": 7504,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-01-06": {
            "id": 7519,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-01-07": {
            "id": 7530,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-01-08": {
            "id": 7544,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-01-11": {
            "id": 7558,
            "accidents": 2,
            "rained": 1,
            "climate": 1
        },
        "2022-01-10": {
            "id": 7559,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-01-13": {
            "id": 7584,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-01-12": {
            "id": 7589,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-01-14": {
            "id": 7623,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-01-15": {
            "id": 7636,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-01-17": {
            "id": 7657,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-01-18": {
            "id": 7667,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-01-19": {
            "id": 7684,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-01-20": {
            "id": 7700,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-01-21": {
            "id": 7728,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-01-22": {
            "id": 7740,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-01-24": {
            "id": 7758,
            "accidents": 2,
            "rained": 1,
            "climate": 1
        },
        "2022-01-25": {
            "id": 7767,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-01-26": {
            "id": 7792,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-01-27": {
            "id": 7807,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-01-28": {
            "id": 7861,
            "accidents": 1,
            "rained": 2,
            "climate": 2
        },
        "2022-01-29": {
            "id": 7871,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-01-31": {
            "id": 7898,
            "accidents": 2,
            "rained": 2,
            "climate": 1
        },
        "2021-12-01": {
            "id": 6971,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-12-08": {
            "id": 6972,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-12-02": {
            "id": 6988,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-12-03": {
            "id": 7002,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-12-04": {
            "id": 7018,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-12-06": {
            "id": 7027,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-12-07": {
            "id": 7053,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-12-09": {
            "id": 7088,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-12-10": {
            "id": 7112,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-12-11": {
            "id": 7137,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-12-13": {
            "id": 7147,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-12-14": {
            "id": 7159,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-12-15": {
            "id": 7187,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-12-16": {
            "id": 7224,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-12-17": {
            "id": 7228,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-12-18": {
            "id": 7235,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-12-20": {
            "id": 7236,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-12-21": {
            "id": 7304,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-12-22": {
            "id": 7316,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-12-23": {
            "id": 7329,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-12-27": {
            "id": 7392,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-12-28": {
            "id": 7413,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-12-29": {
            "id": 7431,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2021-12-30": {
            "id": 7444,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-01": {
            "id": 8353,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-07": {
            "id": 8394,
            "accidents": 1,
            "rained": 1,
            "climate": 2
        },
        "2022-03-04": {
            "id": 8401,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-05": {
            "id": 8407,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-06": {
            "id": 8417,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-08": {
            "id": 8434,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-09": {
            "id": 8446,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-10": {
            "id": 8458,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-11": {
            "id": 8469,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-12": {
            "id": 8478,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-02": {
            "id": 8482,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-03": {
            "id": 8491,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-14": {
            "id": 8507,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-15": {
            "id": 8528,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-16": {
            "id": 8533,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-17": {
            "id": 8547,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-18": {
            "id": 8555,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-19": {
            "id": 8571,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-20": {
            "id": 8577,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-21": {
            "id": 8580,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-22": {
            "id": 8581,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-23": {
            "id": 8628,
            "accidents": 1,
            "rained": 2,
            "climate": 2
        },
        "2022-03-13": {
            "id": 8641,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-24": {
            "id": 8649,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-25": {
            "id": 8657,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-26": {
            "id": 8664,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-27": {
            "id": 8670,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-28": {
            "id": 8682,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-29": {
            "id": 8690,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-30": {
            "id": 8710,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-03-31": {
            "id": 8714,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-02-28": {
            "id": 7885,
            "accidents": 1,
            "rained": 2,
            "climate": 2
        },
        "2022-02-01": {
            "id": 7915,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-02-02": {
            "id": 7946,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-02-03": {
            "id": 7961,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-02-04": {
            "id": 7976,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-02-05": {
            "id": 7985,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-02-07": {
            "id": 8002,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-02-08": {
            "id": 8013,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-02-09": {
            "id": 8024,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-02-10": {
            "id": 8039,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-02-11": {
            "id": 8063,
            "accidents": 1,
            "rained": 2,
            "climate": 1
        },
        "2022-02-12": {
            "id": 8076,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-02-14": {
            "id": 8087,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-02-15": {
            "id": 8111,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-02-16": {
            "id": 8128,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-02-17": {
            "id": 8139,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-02-18": {
            "id": 8160,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-02-19": {
            "id": 8184,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-02-21": {
            "id": 8193,
            "accidents": 1,
            "rained": 1,
            "climate": 2
        },
        "2022-02-22": {
            "id": 8207,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-02-23": {
            "id": 8232,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-02-24": {
            "id": 8243,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-02-25": {
            "id": 8269,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-02-26": {
            "id": 8283,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-02-27": {
            "id": 8293,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-02-20": {
            "id": 8341,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-04-01": {
            "id": 8730,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-04-02": {
            "id": 8744,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-04-03": {
            "id": 8749,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-04-04": {
            "id": 8758,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-04-05": {
            "id": 8766,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-04-06": {
            "id": 8777,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-04-07": {
            "id": 8792,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-04-08": {
            "id": 8810,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-04-09": {
            "id": 8822,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-04-11": {
            "id": 8825,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-04-12": {
            "id": 8826,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-04-18": {
            "id": 8847,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-04-13": {
            "id": 8852,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-04-14": {
            "id": 8855,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-04-16": {
            "id": 8861,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-04-15": {
            "id": 8862,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-04-17": {
            "id": 8878,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-04-19": {
            "id": 8883,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-04-20": {
            "id": 8892,
            "accidents": 1,
            "rained": 2,
            "climate": 3
        },
        "2022-04-21": {
            "id": 8898,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-04-22": {
            "id": 8905,
            "accidents": 1,
            "rained": 1,
            "climate": 2
        },
        "2022-04-23": {
            "id": 8913,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-04-25": {
            "id": 8922,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-04-26": {
            "id": 8923,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-04-27": {
            "id": 8924,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-04-28": {
            "id": 8925,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-04-29": {
            "id": 8926,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-05-02": {
            "id": 8927,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-05-03": {
            "id": 8928,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        },
        "2022-05-04": {
            "id": 8929,
            "accidents": 1,
            "rained": 1,
            "climate": 1
        }
    }

    

    const renderHeader = () => {
        const dateFormat = "MMMM yyyy";

        return (
            <div className='header row flex-middle'>
                <div className='col col-start'>
                    <div className='icon' onClick={prevMonth}>
                        chevron_left
                    </div>
                </div>
                <div className='col col-center'>
                    <span>
                       {format(state.currentMonth, dateFormat)  }
                    </span>
                </div>
                <div className='col col-end'>
                    <div className='icon' onClick={nextMonth}>
                        chevron_right
                    </div>
                </div>
            </div>
        )
    }
    const renderDays = () => {
        const dateFormat = "EEEE";
        let days = [];
   

        let startDate = startOfWeek(state.currentMonth);
        for(let i = 0; i < 7; i++) {
            days.push(
                <div className='col col-center' key={i}>
                    {format(addDays(startDate, i), dateFormat)}
                </div>
            )
        }

        return <div className='days row'>{days}</div>

    }
    const renderClimate = (icon) => {
        
        switch (icon) {
            case 1:
                return <FontAwesomeIcon className='text-primary' icon={faSun}/>
            case 2:
                return <FontAwesomeIcon className='text-muted' icon={faCloudRain}/>
            case 3:
                return <FontAwesomeIcon className='text-warning' icon={faBolt}/>
        }
    }
    const renderCells = () => {
        const monthStart = startOfMonth(state.currentMonth);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);

        const dateFormat = "d";
        const rows = [];

        let days = [];
        let day = startDate;
        let formattedDate = "";

        /*axios.get(`${apiURL}/api/dwr/month/${format(monthStart, 'yyyy-MM')}`)
        .then(response => {
        response.data.data.map(item => {
            data[item.datetime] = {
                id: item.id,
                accidents: item.accidents,
                rained: item.area_condition,
                climate: item.weather_condition
            }
        })
        console.log(data)
        
        
        })*/

        
        while(day <= endDate) {
            for (let i =0; i<7; i++){
                formattedDate = format(day, dateFormat);
                const cloneDay = day;
                const currentDataItem = data[format(day, "yyyy-MM-dd").toString()];
                //console.log(currentDataItem)
                days.push(
                    <div className={`col cell ${currentDataItem ? (currentDataItem.accidents == "2" ? "accident" : "completed"): ""} ${!isSameMonth(day, monthStart) ? "disabled" : isSameDay(day, state.selectedDate) ? "selected" : ""}`} key={day} onClick={() => onDateClick(currentDataItem?.id)}>
                        <span className='number'>{formattedDate}</span>
                        {
                            
                            (currentDataItem) ? (
                            <span className='text'>
                                {renderClimate(currentDataItem.climate)}
                            </span>) : (
                            <span className='text'>

                            </span>)
                        }
                        
                        <span className='bg'>{formattedDate}</span>
                    </div>
                )
                day = addDays(day, 1);
            }
        }
        rows.push(
            <div className='row' key={day}>
                {days}
            </div>
        )
        
        return <div className="body">{rows}</div>;
        

        
    }

    const onDateClick = id => {
        if(!id) return;
        navigate(`/dwr/${id}`);

        return;
    }

    const nextMonth = () => {
        setState({
            ...state,
            currentMonth: addMonths(state.currentMonth, 1)
        })
    }
    const prevMonth = () => {
        setState({
            ...state,
            currentMonth: subMonths(state.currentMonth, 1)
        })
    }

  return (
    <div className="calendar">
        {renderHeader()}
        {renderDays()}
        {renderCells()}
    </div>
  )
}

export default Calendar