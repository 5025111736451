import React from 'react'
import Input from './Input'
import Select from './Select'
import TextArea from './TextArea'
import CustomQA from './CustomQA'
import QAInput from './QAInput'
import QASelect from './QASelect'
import QATextArea from './QATextArea'

function Element({field: {field_type,field_id,field_name,field_label,field_placeholder,field_value,field_options}}) {
    switch(field_type) {
        case 'text':
            return (<Input
                field_id={field_id}
                field_name={field_name}
                field_label={field_label}
                field_value={field_value}
                field_placeholder={field_placeholder}
            />)
        case 'textarea':
            return (<TextArea
                field_id={field_id}
                field_name={field_name}
                field_label={field_label}
                field_value={field_value}
                field_placeholder={field_placeholder}
            />)
        case 'select':
            return (<Select 
                field_id={field_id}
                field_name={field_name}
                field_label={field_label}
                field_value={field_value}
                field_options={field_options}
                
            />)
        case 'qaText':
            return (<QAInput
                field_id={field_id}
                field_name={field_name}
                field_label={field_label}
                field_value={field_value}
                field_placeholder={field_placeholder}
            />)
        case 'qaCustom':
                return (<CustomQA 
                    field_id={field_id}
                    field_name={field_name}
                    field_label={field_label}
                    field_value={field_value}                  
                />)
        case 'qaSelect':
                    return (<QASelect 
                        field_id={field_id}
                        field_name={field_name}
                        field_label={field_label}
                        field_value={field_value}
                        field_options={field_options}
                        
                    />)
        case 'qaTextarea':
            return (<QATextArea
                field_id={field_id}
                field_name={field_name}
                field_label={field_label}
                field_value={field_value}
                field_placeholder={field_placeholder}
            />)
        default:
            return null;
    }
  
    return (
    <div>Element</div>
  )
}

export default Element