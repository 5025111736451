import React from 'react'
import './Dashboard.css'

function Dashboard() {
  return (
    <div className='dashboard'>
        <h3>Dashboard</h3>
    </div>
  )
}

export default Dashboard